myp-editprofile {
  display: block;
  width: 100%;
  color: #eaeaea;
}

myp-editprofile,
.edit-profile-container {
  .accountinfo {
    .profile-photo {
      width: 150px;
      max-width: 90%;
      border-radius: 20px;
      margin: auto;
    }

    .button-list{
      padding: none;
      flex-wrap: wrap;
    }

    .cover-photo-container {
      width: 100%;
      height: 100px;
      border-radius: 5px;
      background-size: cover;
      background-position: center;
      margin-bottom: 10px;

      &.nophoto {
        background-color: #232323;
        display: flex;
        justify-content: center;
        align-items: center;
        &:after {
          content: "No cover photo selected";
          opacity: 0.5;
        }
      }
    }

    #account_form {
      input.form-control,
      textarea.form-control {
        background-color: #232323;
        border: none;
        color: #eaeaea;

        .theme_plainlight & {
          background-color: rgba(0, 0, 0, 0.1);
          color: #333;
        }
      }

      input.form-control::placeholder,
      textarea.form-control::placeholder {
        color: #232323;

        .theme_plainlight & {
          color: rgba(0, 0, 0, 0.3);
        }
      }

      ngb-datepicker {
        background-color: #eaeaea;
      }
    }

    .address-item {
      padding: 10px 0px 10px 10px;
      display: flex;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);

      &:hover {
        background-color: rgba(255, 255, 255, 0.1);
        border-bottom: 1px solid transparent;
        .prod-img {
          transform: scale(1.2, 1.2);
        }
      }

      .address-data {
        align-self: center;
        transition: all 0.1s ease-in-out;
        transform: scale(1, 1);

        .title {
          color: #eaeaea;
          font-size: 17px;
        }

        .extra-info {
          display: block;
          line-height: 17px;
          font-size: 13px;
        }
      }

      .buttons {
        flex-shrink: 0;
        align-self: center;
        margin-left: auto;
        color: #eaeaea;

        fa.action {
          width: 30px;
          height: 30px;
          text-align: center;
          border-radius: 15px;
          display: inline-block;
          margin-right: 10px;
          line-height: 30px;
        }
      }
    }
  }
}

textarea.form-control {
  padding: 0.375rem 0.75rem;
}

.form-text.pad {
  padding: 0 0.75rem;
}
