.selection-buttons {
  position: fixed;
  display: flex;
  justify-content: center;
  bottom: 10px;
  left: 0px;
  width: 100%;

  .button {
    padding: 10px;
    background: $solid-fancy-gradient;
    font-family: $main-font;
    text-align: center;
    border-radius: 10px;
    margin: 10px;
    cursor: pointer;
  }
}

.file-folders {
  .file-breadcrumbs {
    display: flex;
    justify-content: space-between;
    font-weight: bold;
    align-items: center;
    margin-bottom: 5px;

    .folder {
      color: var(--brand-blue);
      cursor: pointer;
    }
  }
}

.move-folder-option {
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: bold;
  padding: 10px;
  border: 1px solid var(--brand-blue);
  border-radius: 5px;
  cursor: pointer;

  &:hover,
  &.selected {
    background-color: var(--brand-blue);
  }
}

.batch-selected-badge {
  position: absolute;
  width: 100px;
  bottom: 20px;
  left: 50%;
  margin-left: -50px;
  background-color: var(--brand-blue);
  color: #eaeaea;
  font-weight: bold;
  border-radius: 10px;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.7);
  text-align: center;
  font-size: 12px;
}

.share-page {
  padding: 0px 10px;
  max-width: 1500px;
  margin: auto;

  .waveform-comments-list-view {
    overflow: auto;
  }
}

.file-format-pill {
  font-size: 10px;
  padding: 0px 3px;
  border-radius: 2px;
  background-color: gray;
  color: #eaeaea;
  text-transform: uppercase;
  font-weight: bold;
  margin-right: 5px;
}
