// web specific variables here...
$orange: orange;
$pink: #ff19a5;
$black: #121212;
$transBG: #232323;
$d-light-blue: #1f8bc2;
$green: #28a745;
$red: red;
$trans-green: rgba(2, 194, 27, 0.438);
$trans-red: rgba(255, 41, 41, 0.623);
$white: #fff;
$dark: #181818;
$grey: #f4f8fb;
$break-small: 767px;
$break-fullwidth: 575px;
$break-tiny: 460px;
$fancy-gradient2: linear-gradient(135deg, rgba(38, 61, 193, 0.5) 0%, rgba(70, 141, 242, 0.57) 37%, rgba(38, 61, 193, 0.699) 100%);
$fancy-gradient: linear-gradient(135deg, rgba(38, 61, 193, 0.5) 0%, rgba(70, 141, 242, 0.57) 37%, rgba(38, 61, 193, 0.699) 100%);
$solid-fancy-gradient: linear-gradient(135deg, rgba(38, 61, 193, 0.9) 0%, rgba(70, 141, 242, 0.9) 37%, rgba(38, 61, 193, 0.9) 100%);
$hover-fancy-gradient: linear-gradient(135deg, rgba(38, 61, 193, 0.2) 0%, rgba(70, 141, 242, 0.2) 37%, rgba(38, 61, 193, 0.3) 100%);
$selected: linear-gradient(135deg, rgba(38, 61, 193, 0.5) 0%, rgba(70, 141, 242, 0.57) 37%, rgba(38, 61, 193, 0.699) 100%);

$brand-dark: #121212;
$brand-yellow: #ffe019;
$brand-gray: #eaeaea;
$brand-pink: #ff19a5;
$brand-orange: #ff7d6b;
$brand-teal: #00f0ff;
$brand-green: #1ab783;
$brand-brightgreen: #0dff87;
$brand-purple: #7e39d7;
$brand-darkgreen: #267a80;
$brand-red: #ff666f;

$brand-blue: #0083ff;
$brand-blue-alt: #1769b8;

:root {
  --brand-blue: #0083ff; // Default blue color
}

body.theme_plainlight {
  --brand-blue: #1769b8; // Light mode color
}

body.theme_plaindark {
  --brand-blue: #0083ff; // Dark mode color
}

$sphere-gradient: linear-gradient(0deg, #2b4050, #1f2f3b);
$sphere-bright-gradient: linear-gradient(125deg, #00b8b3, #5a3082);
$sphere-notbright-gradient: linear-gradient(125deg, #6691b3, #334c5d);

$cloud-selected: #1b222f;
$main-font: "Helvetica Now", sans-serif !important;
@keyframes slide-over-from-right {
  0% {
    opacity: 0;
    transform: translateX(30px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes slide-over-from-left {
  0% {
    opacity: 0;
    transform: translateX(-30px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes slide-down {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slide-down-long {
  0% {
    opacity: 0;
    transform: translateY(-100px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slide-up {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slide-up-long {
  0% {
    opacity: 0;
    transform: translateY(100px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
