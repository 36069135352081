.video-container {
  padding: 10px;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  transform: scale(1, 1);
  display: flex;
  animation: slide-down 0.4s ease;
  border: 1px solid transparent;
  align-items: center;
  align-self: flex-start;

  @media screen and (max-width: $break-tiny) {
    margin: 10px;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.4);
    border: 1px solid #0000008c;
    border-radius: 5px;
    transform: scale(1.04, 1.04);

    .title {
      max-height: none;
    }
  }

  img {
    width: 25%;
    height: auto;
    align-self: flex-start;
    margin-right: 10px;
    max-width: 250px;
  }

  .title {
    font-size: 18px;
    font-weight: bold;
    display: block;
    max-height: 40px;
    line-height: 20px;
    overflow: hidden;
    transition: all 0.1s ease-in-out;
  }

  .details {
    font-size: 14px;
    opacity: 0.6;
    line-height: 18px;
  }
}

#videooverlay-container {
  display: block;
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.98);
  top: 0px;
  left: 0px;
  z-index: 1051;

  .circle-icon-btn {
    color: white !important;
  }

  #videooverlay {
    position: fixed;
    // height: calc(100vh - 20%);
    // top: 10%;
    // left: 50%;
    // overflow: hidden;
    // margin-left: -45%;
    z-index: 999999;
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    flex-direction: column;

    .comments-container {
      width: 100%;
      max-width: 700px;

      .video-sidebar-header {
        height: 70px;
      }
    }
  }

  .video-grid-item {
    background-color: #232323 !important;
  }

  &.minimized {
    width: 400px;
    height: 270px;
    top: auto;
    bottom: 10px;
    padding-top: 40px;

    &.dock-right {
      left: auto;
      right: 10px;
    }

    &.dock-left {
      right: auto;
      left: 10px;
    }

    @media screen and (max-width: $break-small) {
      width: 300px;
      height: 240px;
    }

    #videooverlay {
      position: relative;
    }
  }

  &.landscape {
    #videooverlay {
      flex-direction: row;

      .comments-container {
        width: 20%;
        min-width: 500px;
        height: 100%;
      }
    }
  }

  .close-btn {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 9999999;
    font-size: 35px;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    line-height: 40px;
    border: 1px solid rgba(255, 0, 0, 0.5);
  }

  .pin-container {
    background-color: rgba(0, 0, 0, 0.5);
    font-size: 12px;
    border-radius: 10px;
    margin: 5px 0px 5px 5px;
    display: flex;
    line-height: 8px;
    align-items: center;
    color: #eaeaea;

    label {
      margin: 0px 0px 0px 5px;
      margin-bottom: 0px;
      cursor: pointer;
    }
  }
}

.folder-option {
  padding: 5px;
  margin: 0px 10px 10px 0px;
  border: 1px solid var(--brand-blue);
  border-radius: 5px;
  @extend .brandfont-bold;
  @extend .hover-zoom;
  &.selected {
    background-color: var(--brand-blue);
    color: #eaeaea;
  }
}

.flex-row {
  .video-container:nth-child(1) {
    margin-top: -10px;
  }
}

.minimized-buttons {
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 9999999999;
  width: 97%;
}

.responsive-video-container {
  position: relative;
  padding-bottom: 50%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
}

.responsive-video-container iframe,
.responsive-video-container object,
.responsive-video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.video-details {
  overflow: auto;
}

.video-grid-item {
  position: relative;
  background-color: #232323;
  border-radius: 10px;
  transition: all 0.1s ease-in-out;
  height: 100%;

  .theme_plainlight & {
    background-color: #f5f5f5;
  }

  &:hover {
    transform: scale(1.01, 1.01);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  }

  &.video-border {
    padding: 5px;
    border: 1px solid #5c5c5c;
    border-radius: 10px;

    .theme_plainlight & {
      border: 1px solid gray;
    }
  }

  .close-btn {
    position: absolute;
    top: -5px;
    right: -5px;
    font-size: 18px;
    cursor: pointer;
  }

  .main-data {
    padding: 5px 20px 20px 20px;
  }

  .notes-indicator {
    position: absolute;
    left: 5px;
    top: 5px;
    color: lightgreen;
    font-size: 20px;
  }
}

.note-timestamp {
  color: #eaeaea;
  font-size: 12px;
  display: flex;
  justify-content: left;
  align-items: center;
  width: 100%;
  margin-bottom: 5px;
  padding-left: 10px;

  label {
    margin: 0;
  }
}

.video-note {
  margin: 10px 5px;
  display: flex;
  color: #eaeaea;

  .video-timestamp {
    margin-right: 10px;
    padding: 10px;
    font-size: 12px;
    font-weight: bold;
  }

  .video-note-text {
    border-radius: 10px;
    background-color: $transBG;
    padding: 10px;
  }

  &.active {
    .video-note-text {
      @extend .pulse-blue;
    }
  }
}

.notes-list {
  padding: 0;
}

youtube-player {
  div {
    width: 100%;
    height: 100%;

    iframe {
      width: 100%;
      height: 100%;
    }
  }
}
