.product-category {
  margin-bottom: 20px;

  .category-name {
    font-size: 18px;
    font-weight: bold;
  }

  .category-name,
  .subcategory-name {
    fa-icon {
      cursor: pointer;
      display: none;
      color: var(--brand-blue);
      font-size: 14px;
      margin-right: 5px;
      margin-left: 5px;
      transition: all 0.1s ease-in-out !important;

      &:hover {
        transform: scale(1.2, 1.2);
      }
    }

    &:hover {
      fa-icon {
        display: inline-block;
      }
    }
  }

  .product-subcategory {
    margin: 5px 0px 5px 10px;
  }
}
.user-comments-container {
  width: 100%;
  height: calc(100vh - 281px);
  overflow-y: auto;
  position: relative;
  .user-comments {
    display: flex;
    flex-direction: row-reverse;
    max-width: 1000px;
    margin: 10px auto;
    margin-bottom: 30px;
    align-items: center;

    &.own_comment {
      flex-direction: row;
    }
    ul {
      list-style-type: none;
    }
    li {
      margin-top: 10px;
    }
    .card {
      padding: 10px;
      text-align: left;
      border-radius: 10px;
      background-color: rgba(255, 255, 255, 0.1);

      .theme_plainlight & {
        background-color: white;
      }

      .time-created {
        margin-left: 10px;
      }
    }
    .profile-photo {
      width: 30px;
      height: 30px;
      border-radius: 15px;
      transition: all 0.1s ease-in-out;
      margin-left: 10px;
      margin-right: 10px;

      &:hover {
        transform: scale(1.05, 1.05);
      }
    }
    .author-name {
      color: white;

      .theme_plainlight & {
        color: black;
      }
    }
    .btn {
      margin: 0 5px;
    }
  }
  &.empty-comments {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.input-container {
  width: 100%;
  // .editable-message-span {
  //   background-color: rgba(255, 255, 255, 0.1);
  //   .theme_plainlight & {
  //     background-color: white;
  //   }
  // }
  .send-btn {
    &.disabled {
      background-color: black;
    }
  }
}
.nav {
  flex-wrap: nowrap;
  overflow-x: auto;
}

.notes-input {
  position: fixed;
  position: fixed;
  bottom: 0px;
  left: 0px;
  padding: 10px;
  // background-color: #333;

  .theme_plainlight & {
    // background-color: #f5f5f5;
  }
}

.word-wrap {
  word-wrap: break-word;
}
