/**
 * The following are variables shared across all platforms and apps
 */

// could use a base theme here, for example:
// @import '~@angular/material/theming';

/**
 *  Why NativeScript scss variables here? 
 *  Because they cover some nice ground level variables.
 *  You can use them for multi-platform development (web included).
 *  See here: https://github.com/NativeScript/theme/blob/master/app/scss/_variables.scss
 *  Although feel free to use your own base variables.
 */

// override any imported variables here...

$orange: orange;
$brand-dark: #121212;
$brand-yellow: #ffe019;
$brand-gray: #eaeaea;
$brand-pink: #ff19a5;
$brand-orange: #ff7d6b;
$brand-blue-alt: #1769b8;
$brand-teal: #00f0ff;
$brand-green: #1ab783;
$brand-brightgreen: #0dff87;
$brand-purple: #7e39d7;
$brand-darkgreen: #267a80;
$brand-red: #ff666f;

$transBG: #232323;
$pink: #ff19a5;

$brand-blue: #0083ff;
$brand-blue-alt: #1769b8;

:root {
  --brand-blue: #0083ff; // Default blue color
}

body.theme_plainlight {
  --brand-blue: #1769b8; // Light mode color
}

body.theme_plaindark {
  --brand-blue: #0083ff; // Dark mode color
}

:root {
  --soft-border: rgba(255, 255, 255, 0.1);
}

body.theme_plainlight {
  --soft-border: rgba(0, 0, 0, 0.1);
}

body.theme_plaindark {
  --soft-border: rgba(255, 255, 255, 0.1);
}
