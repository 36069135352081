myp-orders {
  display: block;
  width: 100%;
}

.order-item {
  padding: 10px 0px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  margin-bottom: 50px;

  .header {
    .invoice-id {
      font-weight: bold;
    }
  }

  .title {
    display: inline-block;
    margin-bottom: 20px;
  }
  .order-data {
    margin-top: 5px;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 5px;

    .order-detail-item {
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      display: flex;
      align-items: center;
      font-size: 14px;
      padding: 5px;

      &:last-child {
        border-bottom: none;
      }
      img {
        width: 30px;
        margin-right: 5px;
      }
    }
  }
}

.tickets {
  .ng-star-inserted {
    width: 100%;
    .order-item {
      margin-bottom: 0px;
      padding: 10px;
      &:hover {
        transform: scale(1.02, 1.02);
        background: rgba(0, 0, 0, 0.5);
        border-radius: 5px;
        border: 1px solid black;
        box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.7);
      }
    }
  }
}
.rma-items-wrapper {
  padding: 0 15px;
  .rma-items-list {
    display: flex;
    justify-content: space-between;
    line-height: 2;
    .item-detail {
      color: #eaeaea;
    }
  }
}
.trans-header {
  margin-bottom: 15px;
}
.grey-status-title {
  color: #eaeaea;
  opacity: 0.7;
}
.grey-status-reason {
  opacity: 0.6;
}
