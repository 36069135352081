footer {
  color: #eaeaea;
  margin-top: 100px;

  ul {
    margin: 0;
    padding: 0;

    li {
      list-style: none;
      margin: 0;
      padding: 5px 0;
      font-size: 16px;
      font-weight: 400;
      cursor: pointer;

      a {
        color: #eaeaea;

        &:hover {
          color: #eaeaea;
          text-decoration: none;
          opacity: 0.8;
        }
      }
    }
  }

  .footer-social {
    li {
      display: inline-block;
      padding-right: 15px;

      img {
        width: 25px;
        height: 25px;
      }
    }
  }

  .footer-breadcrumbs {
    padding: 15px 0 0;
    font-size: 14px;
    border-bottom: 1px solid #888;

    ul {
      list-style-type: none;
      margin: 0 0 0 15px;
      padding: 0 0 10px 0;
      width: 100%;

      li {
        display: inline;
        margin: 0;
        padding: 0 15px;
        border-right: 1px solid #fefefe;
        vertical-align: middle;
        cursor: pointer;
        font-size: 14px;
      }
    }

    img {
      padding-bottom: 3px;
      width: 25px;
    }
  }

  h3 {
    font-size: 25px;
    font-weight: 300;
    margin: 20px 0 10px;
  }

  .footer-bottom {
    font-size: 12px;
    font-weight: 400;
    margin: 30px 0 20px 0;

    .footer-logo {
      max-width: 200px;
    }

    .footer-tagline {
      display: inline-block;
      margin-top: 15px;
      font-size: 14px;
    }

    .footer-corplinks {
      margin: 0;
      padding: 40px 0 0;

      li {
        list-style: none;
        display: inline;
        font-weight: 400;
        font-size: 12px;

        a {
          color: #eaeaea;
        }

        &:after {
          content: "|";
          padding: 0 5px;

          &:last-of-type {
            content: "";
          }
        }
      }
    }
  }

  .black-letter{
    color: inherit;
    .theme_plainlight & {
      color: #333;
    }
  }
}
