myp-support {
  // display: block;
  // width: 100%;
  // // margin-top: 30px;
  // color: #eaeaea;

  .page-nav-header {
    .buttons {
      .circle-icon-btn {
        margin-left: 20px;
      }
    }
  }

  .search {
    padding: 10px;
    background-color: $transBG;
    color: #eaeaea;
    font-weight: bold;
    border: none;
    outline: none;
    border-radius: 10px;
    font-size: 16px;
    width: 100%;

    &::placeholder {
      color: #333;
      font-weight: lighter;
    }
  }

  .support-link {
    text-decoration: none !important;
    transition: all 0.1s ease-in-out;
    transform: scale(1, 1);
    margin-bottom: 20px;

    &:hover {
      text-decoration: none !important;
      transform: scale(1.02, 1.02);
    }

    fa-icon {
      font-size: 50px;
      text-align: center;
      color: #eaeaea;
    }

    span.link {
      color: var(--brand-blue);
    }

    span.text {
      font-size: 14px;
      opacity: 0.7;
      color: #eaeaea;
    }
  }
}

myp-ticketdetail,
myp-shared-ticket-detail {
  display: block;
  width: 100%;

  .comment {
    margin-bottom: 40px;

    .comment-body {
      padding: 10px;
      background-color: rgba(0, 0, 0, 0.3);
      border-radius: 10px;

      &.me {
        background-color: rgba(0, 60, 189, 0.322);
      }

      img {
        width: 500px;
        max-width: 100%;
      }
    }

    .author-photo {
      border-radius: 100px;
      width: 100%;
    }

    .author-details {
      font-size: 12px;
    }
  }
}
myp-knowledgebase {
  .kb-category {
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.5);
    margin: 10px;
    border-radius: 10px;
  }
}
