myp-shared-products-list {
  width: 100%;
  display: block;
}

.view_switcher {
  font-size: 30px;
  fa-icon {
    display: inline-block;
    width: 40px;
    height: 40px;
    opacity: 0.7;
    text-align: center;
    line-height: 40px;
    border-radius: 5px;
    margin-left: 10px;
    color: #eaeaea;
    cursor: pointer;
    &.selected {
      background-color: var(--brand-blue);
      opacity: 1;
    }
  }
}

myp-product-detail,
myp-shared-product-detail {
  display: block;

  .flagship {
    // background-color: rgba(255, 255, 255, 0.1);
    // padding: 10px;
    // border-radius: 10px;
    position: relative;

    .theme_plainlight & {
      // background-color: #f5f5f5;
    }

    .title {
      position: relative;
      @extend .brandfont-black;
    }
  }

  .version {
    align-self: flex-end;
    margin-left: 10px;
    font-size: 13px;
    opacity: 0.6;
    line-height: 25px;
    .cloud-app & {
      align-self: center;
    }
  }

  .sticky-sidebar {
    position: fixed;
    top: 90px;
    @media screen and (max-width: 576px) {
      position: relative !important;
      top: 0px;
    }
  }

  .prod_img-container {
    // background-color: #333;
    border-radius: 10px;
    margin-bottom: 20px;

    @media screen and (max-width: 576px) {
      background-color: transparent;
      padding: 10px;
    }

    .prod_img {
      width: 100%;

      @media screen and (max-width: 576px) {
        margin: auto;
        display: block;
        max-width: 200px;
      }

      // @media screen and (max-width: $break-tiny) {
      //   float: none;
      //   margin: auto auto 10px auto;
      //   width: 60%;
      //   display: block;
      // }
    }
  }
  .prod_details {
    .install_instructions_header {
      font-weight: bold;
      @extend .brandfont-black;
      // border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    }
    #text_details {
      max-width: 70%;
      @media screen and (max-width: $break-tiny) {
        max-width: 100%;
      }
    }
    .detail_keys {
      margin-bottom: 20px;
      .key {
        color: var(--brand-blue);
      }
      .unlock-key {
        font-size: 12px;
      }
      .popover {
        .popover-body {
          text-align: center;
          .btn {
            margin: 10px;
          }
        }
      }
      .serial-btn {
        border-radius: 13px;
        margin: 5px 10px 5px 0px;
        font-size: 12px;
        display: inline-block;
        transition: all 0.1s ease-in-out;
        transform: scale(1, 1);
        background-color: $transBG;
        &:hover {
          transform: scale(1.1, 1.1);
        }
        &:not(.info) {
          cursor: pointer;
        }
        &.info {
          background-color: transparent;
          transform: scale(1, 1);
        }
        &.upgrade {
          background-color: rgba(2, 194, 27, 0.438);
        }
        &.manage,
        &.transfer,
        &.offline-activation {
          background-color: var(--brand-blue);
        }
        &.offline-activation-btn {
          color: #eaeaea;
          border: none;
        }
        &.deregister {
          background-color: rgba(255, 41, 41, 0.623);
        }
      }
      .small-btn {
        margin-right: 10px;
        padding: 0px;
      }
    }
    #installer_buttons {
      display: flex;
      flex-wrap: wrap;
      .installer_btn_holder {
        margin: 10px 10px 10px 0px;
      }
    }
  }
  .option-card {
    margin: 10px 0px;
    display: flex;
    padding: 5px;
    border-radius: 5px;
    flex-wrap: wrap;
    &:hover {
      background-color: rgba(255, 255, 255, 0.05);
    }
    img {
      width: 100px;
      height: auto;
      align-self: center;
    }
    .title {
      font-size: 16px;
      font-weight: bold;
    }
    .details {
      opacity: 0.6;
      font-size: 14px;
    }
    .buttons {
      margin-left: auto;
      align-self: center;
      margin-right: 5px;
      display: flex;
      .btn {
        margin: 0px 3px;
      }
    }
  }
}

.download-installer-btn {
  font-weight: 700 !important;
  line-height: 24px !important;
  background-color: transparent !important;
  border: 3px solid var(--brand-blue) !important;
  color: var(--brand-blue) !important;
  border-radius: 3px !important;
  font-size: 18px !important;
  padding: 5px 12px 6px !important;
}

// MODALS
.manage-activations {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  font-size: 14px;
  border-bottom: 1px solid $transBG;
  align-items: center;
}

#upgrade_paths {
  .base-asset {
    padding: 5px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 5px;
    margin: 10px 0px;
  }
}

.product-sort,
.file-sort {
  display: flex;
  align-items: center;
  justify-content: center;

  .sort-option {
    margin-right: 15px;
    font-size: 14px;
    transition: all 0.1s ease-in-out;
    transform: scale(1, 1);
    white-space: nowrap;
    &:hover {
      transform: scale(1.1, 1.1);
    }
    span,
    button {
      cursor: pointer;
      padding: 6px 9px;
      font-size: 14px;
      background-color: $transBG;
      outline: none;
      user-select: none;

      .theme_plainlight & {
        background-color: #f5f5f5;

        &.selected,
        &.selected fa-icon {
          background-color: var(--brand-blue);
          color: #eaeaea;
        }
      }
      &.selected,
      &.selected fa-icon {
        background-color: var(--brand-blue);
        color: #eaeaea;
      }
    }

    &.one-option {
      span,
      button {
        border-radius: 30px;
      }
    }
    &.two-option {
      span {
        border-right: 1px solid rgba(255, 255, 255, 0.2);
      }
      span:first-child {
        border-radius: 30px 0px 0px 30px;
      }
      span:last-child {
        border-right: none;
        border-radius: 0px 30px 30px 0px;
      }
    }
  }
}

.product-filter,
.item-filter {
  display: flex;
  justify-content: center;
  position: relative;
  margin-top: 10px;
  align-items: center;

  @media screen and (max-width: 870px) {
    flex-wrap: wrap;
  }
}
.filter-input {
  width: 100%;
  display: flex;
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 30px;
  overflow: hidden;

  .theme_plainlight & {
    background-color: rgba(0, 0, 0, 0.05);
  }

  @media screen and (max-width: 870px) {
    margin-top: 15px;
  }

  &.no-max-width {
    max-width: 100%;
  }
  position: relative;
  input {
    padding: 5px 15px;
    height: 36px;
    background-color: transparent;
    font-weight: bold;
    border: none;
    outline: none;
    width: 100%;
    color: #eaeaea;
    flex: 1;
    .theme_plainlight & {
      // background-color: rgba(0, 0, 0, 0.05);
      color: black;
    }
  }
  .search-btn {
    background-color: var(--brand-blue);
    opacity: 0.3;
    color: #eaeaea;
    height: 36px;
    line-height: 36px;
    cursor: pointer;
    display: block;
    padding: 0px 10px;
    font-weight: bold;
    border-radius: 0px 30px 30px 0px;

    &.active {
      opacity: 1;
    }
  }
  .clear {
    margin-right: 10px;
    display: flex;
    align-items: center;
    top: 0px;
    cursor: pointer;
  }
  .search {
    margin-left: 10px;
    display: flex;
    align-items: center;
    top: 0px;
    cursor: pointer;
  }
}

.offer {
  // border: 3px solid gray;
  border-radius: 10px;
  padding: 10px;
  text-align: center;
  flex: 1;
  flex-wrap: wrap;
  margin: 10px;
  display: flex;
  flex-direction: column;
  min-width: 250px;
  cursor: pointer;

  img {
    align-self: center;
  }

  &.selected {
    .theme_plainlight & {
      background-color: #f5f5f5;
    }
  }

  ul {
    text-align: left;
  }

  .term-chooser {
    margin-top: 10px;
    display: flex;
    align-items: center;

    .term {
      flex: 1;
      display: block;
      border: 1px solid gray;
      border-radius: 5px;
      margin: 10px;
      cursor: pointer;
      font-weight: bold;

      &.selected {
        background-color: var(--brand-blue);
        color: #eaeaea;
      }
    }
  }
}

.unread-badge {
  width: 10px;
  height: 10px;
  border-radius: 5px;
  margin-right: 5px;
  background-color: var(--brand-blue);
  align-self: flex-start;
  flex-shrink: 0;
  margin-top: 4px;

  myp-product-item & {
    align-self: center;
    margin-top: 0px;
  }
}

myp-transfer-product {
  myp-checkout-product-details {
    img {
      max-width: 90%;
    }
  }
}
myp-main-products {
  aside {
    nav {
      ul {
        &.menu-options {
          li a {
            color: inherit;
            text-decoration: none;
          }
          a:focus-visible {
            border: 1px solid var(--brand-blue);
          }

          li.active {
            > a {
              color: var(--brand-blue);
              border-bottom: 2px solid var(--brand-blue);
            }
          }
        }
      }
    }
  }
}

.installer_btn_holder {
  .installer-btn-pill {
    padding: 10px;
    border-radius: 10px;
    @extend .trans-bg;
    margin: 0px 10px 10px 0px;
  }
}
