// .onboarding-bg {
//   width: 100vw;
//   height: 100vh;
//   position: fixed;
//   background-image: url("/assets/images/sphere-signup-form-bg.png");
//   background-size: cover;
//   background-attachment: fixed;
//   background-position: center;
// }

// .modal-onboarding-bg {
//   width: 100%;
//   height: 100%;
//   position: absolute;
//   background-image: url("/assets/images/sphere-signup-form-bg.png");
//   background-size: cover;
//   background-attachment: fixed;
//   background-position: center;
// }

.onboarding-container,
.onboarding-container-2024 {
  position: relative;
  max-width: 800px;
  width: 100%;
  margin: auto;
  padding-top: 20px;

  @media screen and (max-width: $break-small) {
    padding-top: 10px;
  }

  .logo {
    width: 220px;
    margin: 20px 5px;
  }

  .branding {
    .title {
      letter-spacing: 15px;
      font-size: 25px;
      margin: 20px 0;
    }
  }

  input.form-control,
  textarea.form-control {
    background-color: #232323;
    border: none;
    color: #eaeaea;
  }

  input.form-control::placeholder,
  textarea.form-control::placeholder {
    color: #232323;
  }
}

.feature-list {
  margin: 0px;
  padding: 0px;

  li {
    list-style: none;
    font-weight: bold;
    margin: 10px;
    background-color: #232323;
    padding: 5px 10px;
    border-radius: 17px;

    .theme_plainlight & {
      background-color: #eaeaea;
    }
  }

  fa-icon {
    color: var(--brand-blue);
  }
}

myp-sphere-onboarding-stripe {
  .is-modal {
    background-color: #1b8efc !important;
    background: #1b8efc !important;
    background-attachment: fixed;
    color: white !important;
  }
}
.modal-content {
  myp-sphere-onboarding-stripe {
    .form-control {
      background-color: #232323 !important;
      color: #eaeaea;
      border: none;

      .theme_plainlight & {
        color: #333;
        background-color: rgba(0, 0, 0, 0.2) !important;

        option {
          color: #333;
        }
      }
    }
  }
}

.onboarding-container-2024 {
  max-width: 1200px;

  .onboarding-flow-2024 {
    .options {
      padding: 20px 0px 0px 0px !important;
      justify-content: space-between;
      .option {
        transition: all 0.1s ease-in-out;
        transform: scale(1, 1);
        min-width: 45%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 40px 40px;
        border-radius: 30px;
        cursor: pointer;
        height: 100%;
        background: linear-gradient(to bottom left, #2e2e2e, #000);
        border: 2px solid #242525;

        .theme_plainlight & {
          background: linear-gradient(45deg, rgba(0, 131, 255, 0.11) 0%, rgba(251, 253, 255, 0.02) 57%, rgba(255, 255, 255, 0.02) 58%);
          border: 2px solid #939393;
        }

        .option-title {
          justify-content: center;
          margin-bottom: 20px;
          .icon {
            width: 30px;
            height: auto;
            object-fit: contain;
            margin-left: 10px;
          }
        }

        .option-pill-descriptor {
          font-size: 16px;
          font-weight: bold;
          padding: 11px 20px;
          border-radius: 30px;
          display: inline-block;
          margin: 15px 0px;
          color: white;

          &.blue {
            background-color: var(--brand-blue);
          }

          &.gradient {
            background: -webkit-linear-gradient(left, #ff6153, #ff1391);
          }

          &.purple {
            background-color: #9900ff;
          }
        }

        .option-short-description {
          margin: 10px 0px;
          font-size: 14px;
        }

        .bundle-image {
          margin-bottom: 30px;
        }

        .option-pricing {
          margin-top: auto;
          .price {
            @extend .brandfont-black;
            font-size: 30px;
          }
          .price-blurb {
            font-size: 13px;
          }
        }

        &:hover,
        &:active,
        &:focus {
          background: linear-gradient(45deg, rgba(0, 131, 255, 0.11) 0%, rgba(251, 253, 255, 0.02) 57%, rgba(255, 255, 255, 0.02) 58%);
          border: 2px solid var(--brand-blue);
          box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.3);

          .brand-btn-reverse {
            background-color: var(--brand-blue);
            color: white;
            border: 2px solid var(--brand-blue);
          }

          .theme_plainlight & {
            background: linear-gradient(45deg, rgba(0, 131, 255, 0.11) 0%, rgba(251, 253, 255, 0.02) 57%, rgba(255, 255, 255, 0.02) 58%);
            border: 2px solid var(--brand-blue);
            box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.3);
          }
        }

        // img {
        //   width: 80%;
        //   max-width: 200px;
        //   margin: 0px auto 10px auto;
        // }

        .plan-title {
          font-size: 30px;

          @media screen and (max-width: $break-small) {
            font-size: 20px;
          }
        }

        &.selected {
          background: linear-gradient(45deg, rgba(0, 131, 255, 0.11) 0%, rgba(251, 253, 255, 0.02) 57%, rgba(255, 255, 255, 0.02) 58%);
          border: 2px solid var(--brand-blue);
          box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.3);

          .theme_plainlight & {
            background: linear-gradient(45deg, rgba(0, 131, 255, 0.11) 0%, rgba(251, 253, 255, 0.02) 57%, rgba(255, 255, 255, 0.02) 58%);
            border: 2px solid var(--brand-blue);
            box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.3);
          }
        }
      }
    }
  }
}
