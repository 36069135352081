.action-chooser-container {
  position: fixed;
  top: 0px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 99999999999;
  opacity: 0;
  transition: all 0.2s ease-in-out;

  &.show {
    opacity: 1;

    .action-list {
      transform: translate(0px, 0px);
    }
  }

  &.hide {
    opacity: 0;

    .action-list {
      transform: translate(0px, 400px);
    }
  }

  .action-list {
    width: 500px;
    max-width: 100%;
    background-color: #333;
    margin: 20px;
    border-radius: 20px;
    overflow: hidden;
    text-align: center;
    transform: translate(0px, 400px);
    transition: all 0.2s ease-out;

    .action-title,
    .action-item {
      padding: 13px;
    }

    .action-title {
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      font-size: 15px;
      color: gray;
      max-height: 200px;
      overflow: auto;
    }

    .action-item {
      font-weight: bold;
      font-size: 17px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      cursor: pointer;
      color: var(--brand-blue);

      &.cancel {
        color: red;
      }

      &:hover {
        background-color: #232323;
      }

      &:last-child {
        border: none;
      }
    }
    button {
      &.action-item {
        border-radius: 0 !important;
        margin-top: 0px;
      }
    }
  }
}
