.switch {
  display: flex;
  align-items: center;

  &__label {
    font-size: 14px;
    margin-right: 8px;
  }

  &__track {
    height: 28px;
    width: 56px;
    border-radius: 34px;
    cursor: pointer;
  }

  &__handle {
    height: 24px;
    width: 24px;
    border-radius: 52px;
    background-color: white;
    transform: translate(2px, 2px);
    transition: transform 150ms ease;

    &.active {
      transform: translate(30px, 2px);
    }
  }
}