span#post-subject-input:focus-visible,
#post-input,
.editable-message-span {
  outline: none;
}

.quill-container {
  width: 100%;

  &.bg-full {
    background-color: #232323;
    border-radius: 10px;

    .theme_plainlight & {
      background-color: rgba(0, 0, 0, 0.05);
    }
  }

  .ql-toolbar.ql-snow {
    border: none;
    border-top: 1px solid rgba(255, 255, 255, 0.05);

    .ql-formats {
      button {
        color: white;
      }
    }
  }
  .ql-tooltip[data-mode="link"] {
    position: static !important;
    background-color: #2e2e2e;
    color: white;
    box-shadow: none;
    border: none;

    input {
      border-radius: 5px;
    }

    .theme_plainlight & {
      background-color: #e9e9e9;
      color: #333;
    }
  }

  .ql-container.ql-snow {
    border: none;
    border-radius: 0;
  }

  .ql-snow .ql-stroke {
    stroke: white;

    .theme_plainlight & {
      stroke: grey;
    }
  }

  .ql-snow .ql-fill {
    fill: white;

    .theme_plainlight & {
      fill: grey;
    }
  }
  .ql-editor {
    font-size: 16px;

    .ql-code-block-container {
      background-color: #171717 !important;
      color: #f8f8f2 !important;

      .theme_plainlight & {
        background-color: #dcdcdc !important;
        color: #333 !important;
      }
    }
  }

  .ql-editor.ql-blank::before {
    color: grey;
  }
}

.create-post-container {
  .ql-toolbar.ql-snow {
    display: none;
  }

  &.create-post-active {
    .ql-toolbar.ql-snow {
      display: block !important;
    }
  }
}
