/** Bootstrap overrides
========================================================================== */

// Reassign color vars to semantic color scheme
//$brand-primary : $green;
//$brand-success:             $green !default;
//$brand-info:                $teal !default;
//$brand-warning:             $orange !default;
//$brand-danger:              $red !default;
//$brand-inverse:             $gray-dark !default;
$text-muted: rgba(255, 255, 255, 0.8) !default;

$font-family-sans-serif: pragmatica, sans-serif;
$font-family-serif: "GeoSlab703", serif;

.modal-backdrop.show {
  opacity: 0 !important;
}

.blurme {
  filter: blur(10px);
  .modal-content {
    transition: all 0.3s ease-in-out;
    transform: scale(0.94);
  }
}

@keyframes zoomOutModal {
  from {
    transform: scale(1.05);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes zoomInModal {
  from {
    transform: scale(1);
    opacity: 1;
  }
  to {
    transform: scale(1.05);
    opacity: 0;
  }
}

.modal-dialog {
  animation: zoomOutModal 0.3s ease !important;
  transition: all 0.3s ease-in-out !important;
}

@keyframes zoomOutModal {
  from {
    transform: scale(1.05);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

.modal.fade:not(.show) .modal-dialog {
  animation: zoomInModal 0.3s ease !important;
  transition: all 0.3s ease-in-out !important;
}

ngb-modal-window {
  background-color: rgba(0, 0, 0, 0.8);

  .theme_plainlight & {
    background-color: rgba(255, 255, 255, 0.8);
  }
}

.form-group {
  label.control-label {
    font-weight: bold;
    font-size: 13px;
    margin-bottom: 5px;
    display: block;
  }
}

code {
  color: #e20303;
}

.form-control {
  border-radius: 10px;
  border: none;
  &:disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }
  select {
    width: 100%;
    // background-color: transparent;
    // color: white;
    border: none;
    outline: none;
  }
}

.theme_plaindark {
  .form-control {
    background-color: rgba(255, 255, 255, 0.05) !important;
    color: #eaeaea;
    &:focus {
      option {
        color: #333;
      }
    }
  }
}

.theme_plainlight {
  .form-control {
    background-color: rgba(0, 0, 0, 0.05) !important;
    &:focus {
      option {
      }
    }
  }
}

input::placeholder {
  color: #616161 !important;
  opacity: 1 !important;
  font-size: 0.9rem !important;
}

.theme_plainlight {
  .form-control {
    background-color: rgba(0, 0, 0, 0.05) !important;
    border: 0.5px solid #333;

    &:focus {
      border: none;
      option {
        color: #333;
      }
    }
  }
}

.form-control:focus {
  outline: 0;
  box-shadow: 0 0 0 1px var(--brand-blue);
}

// .form-control {
//   background-color: #232323 !important;
//   color: #eaeaea;
//   border-radius: 10px;
//   border: none;

//   // style when has disabled attribute
//   &:disabled {
//     cursor: not-allowed;
//     opacity: 0.7;
//   }

//   select {
//     width: 100%;
//     background-color: transparent;
//     color: white;
//     border: none;
//     outline: none;
//   }

//   .theme_plainlight & {
//     select {
//       color: #333 !important;
//     }
//     color: #333 !important;
//     background-color: rgba(0, 0, 0, 0.1) !important;

//     option {
//       color: #333;
//     }
//   }
// }

// .form-control:focus {
//   color: #eaeaea;
//   outline: 0;
//   box-shadow: 0 0 0 1px var(--brand-blue);;

//   .theme_plainlight & {
//     color: #333;
//   }

//   option {
//     background-color: #333;
//   }
// }

.modal-content {
  transition: all 0.1s ease-in-out;
  animation: slide-down 0.4s ease;
  background-color: transparent !important;
  color: white !important;
  // box-shadow: 0px 0px 55px rgba(255, 255, 255, 0.1);
  border-radius: 1em;
  border: none;
  .theme_plainlight & {
    background-color: transparent !important;
    color: #333 !important;
    // box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
    border: none;
  }

  ngb-datepicker {
    background-color: white;
  }

  // .form-control {
  //   background-color: #232323 !important;
  //   color: #eaeaea;
  //   border: none;

  //   .theme_plainlight & {
  //     color: #333;
  //     background-color: rgba(143, 143, 143) !important;

  //   }
  // }

  .form-control:focus {
    color: #eaeaea;
    outline: 0;
    // box-shadow: 0 0 0 0.1rem rgba(0, 123, 255, 0.25);

    .theme_plainlight & {
      color: #333;

      option {
        background-color: #f5f5f5;
      }
    }

    option {
      background-color: #333;
    }
  }
  .close {
    text-shadow: none;
    opacity: 1;

    &:not(:disabled):not(.disabled):focus,
    &:not(:disabled):not(.disabled):hover {
      //color: #eaeaea;
      opacity: 1;
    }
  }

  .modal-header {
    position: relative;
    border-bottom: none;
    border-radius: 1em 1em 0px 0px;
    .close {
      color: white;
    }
    .theme_plainlight & {
      .close {
        color: #333;
      }
    }
  }

  .modal-footer {
    border-top: 1px solid rgba(255, 255, 255, 0.04);

    .theme_plainlight & {
      border-top: 1px solid rgba(0, 0, 0, 0.04);
    }
  }
}

.opaque-modal-content .modal-content {
  background-color: rgba(27, 34, 47, 1) !important;
}
.opaque-modal-content.theme_plainlight .modal-content {
  background-color: rgb(245, 245, 245, 1) !important;
}

.modal-backdrop {
  z-index: 1040 !important;
}

.modal-content-over-video {
  .modal-backdrop {
    z-index: 1060 !important;
  }

  .modal {
    z-index: 1061 !important;
  }
}

.clear-modal-content .modal-content {
  background-color: transparent !important;
  box-shadow: none !important;
}

.text-muted {
  color: #62696f !important;
}

// Body
//$body-bg : $white;
//$body-color : $black-light;

/** Finally import Bootstrap framework
========================================================================== */
//@import '~bootstrap/scss/bootstrap.scss';
