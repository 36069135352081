@mixin flex-align-justify-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

myp-learn-detail {
  color: #eaeaea;
}

.left-column-list {
  ul {
    margin: 0px 10px 0px 0px;
    padding: 0px;
    min-width: 130px;

    li {
      margin: 0px;
      padding: 5px;
      list-style: none;
      font-family: $main-font;
      text-transform: uppercase;
      cursor: pointer;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);

      &.selected,
      &:hover {
        background-color: $transBG;
      }
    }
  }
}

.featured-carousel {
  padding: 20px 20px 0px 20px;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.8);
  flex-direction: column;
  background-position: center;
}
.carousel-dot {
  width: 12px;
  height: 12px;
  border-radius: 6px;
  background-color: rgba(0, 0, 0, 0.9);
  display: inline-block;
  margin: 4px;
  cursor: pointer;

  &.selected {
    background-color: #0088cc;
  }
}

.edit-video {
  .add-tag-btn {
    background-color: rgba(255, 255, 255, 0.1);
    padding: 5px 8px;
    border-radius: 15px;
    margin: 5px;
    font-size: 14px;
    font-weight: bold;
    display: inline-block;
    cursor: pointer;

    &:hover {
      background-color: var(--brand-blue);
    }

    .theme_plainlight & {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }

  .associated-tag {
    background-color: rgba(255, 255, 255, 0.1);
    padding: 2px 5px;
    border-radius: 10px;
    margin: 2px 3px;
    font-size: 14px;

    fa-icon {
      cursor: pointer;
    }

    .theme_plainlight & {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }
}

.learn-content-details {
  .learn-content-contents {
    padding: 15px;

    .content-title {
      font-size: 1.5rem;
    }
  }

  .learn-content-percentage {
    width: 100%;
    padding: 10px 20px;
    border-radius: 20px;
    @extend .trans-bg;
    font-weight: bold;
    position: relative;
    overflow: hidden;

    span {
      position: relative;
    }

    .slider {
      position: absolute;
      background-color: var(--brand-blue);
      top: 0px;
      left: 0px;
      height: 100%;

      .theme_plainlight & {
        background-color: var(--brand-blue);
      }
    }
  }
}

.list-item.learn-list-item {
  .image-container {
    position: relative;
    align-self: flex-start;

    // .item-image {
    //   margin-left: 0px!important;
    //   margin-right: 0px!important;
    //   width: 100%;
    //   height: auto;
    // }

    .done-indicator {
      @include flex-align-justify-center;

      position: absolute;
      top: 0px;
      width: 100%;
      color: lightgreen;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.4);
      border-radius: 5px;
      font-size: 22px;
    }
  }

  .video-tags {
    span {
      &:hover {
        color: var(--brand-blue);
      }
    }
  }

  .video-metadata {
    display: flex;
    flex-wrap: wrap;
    font-weight: bold;
    margin-bottom: 0px;

    .video-metadata-item {
      margin: 5px 15px 5px 0px;

      &.green {
        color: $green;
      }

      &.orange {
        color: $orange;
      }

      &.red {
        color: $red;
      }

      &.link {
        color: var(--brand-blue);
        cursor: pointer;
      }

      fa-icon {
        margin-right: 4px;
      }
    }
  }
}

.video-grid-item {
  cursor: pointer;
  overflow: hidden;
  border: none;

  .card-header {
    border-bottom: none;
    height: 180px;
  }

  .card-body {
    padding: 15px;
  }
  .video-title-container {
    height: 48px;
    overflow: hidden;

    .video-title {
      margin: auto 0px;
      line-height: 22px;
      font-weight: bold;
    }
  }
  .progress {
    height: auto;
    border-radius: 0px;
    background-color: #545454;

    .theme_plainlight & {
      background-color: #bababa;
    }

    .progress-indicator {
      height: 4px;
      background-color: var(--brand-blue);
    }
  }

  .video-tags {
    margin-bottom: 5px;
  }

  .image-container {
    border-radius: 5px 5px 0px 0px;
    cursor: pointer;
    position: relative;
    padding: 0;

    .video-grid-item-image {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }

    .done-indicator,
    .play-indicator {
      @include flex-align-justify-center;

      position: absolute;
      top: 0px;
      width: 100%;
      color: #eaeaea;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.4);
      border-radius: 5px;
      font-size: 22px;
      transition: all 0.1s ease-in-out;
    }

    .index-indicator {
      position: absolute;
      top: -5px;
      left: -5px;
      background-color: rgba(0, 0, 0, 0.9);
      height: 40px;
      width: 40px;
      border-radius: 20px;
      box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
      line-height: 40px;
      text-align: center;
      font-size: 30px;
      font-weight: bold;
      color: #eaeaea;
    }

    .play-indicator {
      // display: none;
      opacity: 0;

      fa-icon {
        transform: translate(0px, 10px);
        transition: all 0.1s ease-out;
      }
    }

    &:hover {
      .play-indicator {
        display: flex;
        opacity: 1;

        fa-icon {
          transform: translate(0px, 0px);
        }
      }
    }
  }

  .sub-data {
    font-size: 13px;
    display: inline-block;
    line-height: 17px;

    .description {
      max-height: 34px;
      overflow: hidden;
      margin: 10px 0px;
    }
  }
  ul {
    &.video-tags {
      a {
        cursor: pointer;
        transition: all 0.1s ease-in-out;
        margin-right: 10px;
        &:hover {
          color: var(--brand-blue);
        }
      }
    }
  }

  .video-metadata {
    display: flex;
    flex-wrap: wrap;
    font-weight: bold;
    align-items: center;
    margin-bottom: 0px;

    .video-metadata-item {
      margin: 5px 15px 5px 0px;
      span {
        margin: 5px 15px 5px 0px;
      }

      &.green {
        color: $green;
      }

      &.orange {
        color: $orange;
      }

      &.red {
        color: $red;
      }

      &.link {
        color: var(--brand-blue);
        cursor: pointer;
      }

      fa-icon {
        margin-right: 4px;
      }
    }

    button {
      margin: 0;
      padding: 0.5rem !important;
      cursor: pointer;
      color: var(--brand-blue);

      fa-icon {
        color: var(--brand-blue);
      }
    }
  }
}

.learn-content-filters {
  select {
    max-width: 180px;
  }
}

.scratchpad {
  background-color: transparent;
  border: none;
  width: 100%;
  height: 100%;
  color: #eaeaea;
}

/* in-flight clone */
.gu-mirror {
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important;
  opacity: 0.8;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
  filter: alpha(opacity=80);
  pointer-events: none;
}
/* high-performance display:none; helper */
.gu-hide {
  left: -9999px !important;
}
/* added to mirrorContainer (default = body) while dragging */
.gu-unselectable {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}
/* added to the source element while its mirror is dragged */
.gu-transit {
  opacity: 0.2;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
  filter: alpha(opacity=20);
}

.fullWidthBtn {
  width: 100%;
}
.custom-sr-only {
  height: 0;
  overflow: hidden;
  position: absolute;
}
