@import "variables";

myp-exchange-home {
  color: #eaeaea;

  .jumbotron {
    height: 100vh;
    display: flex;
    align-items: center;

    h1 {
      text-shadow:
        0 0 10px rgba(255, 255, 255, 0.5),
        0 0 20px rgba(255, 255, 255, 0.5),
        0 0 30px #419aaa,
        0 0 40px #419aaa,
        0 0 50px #419aaa,
        0 0 60px #419aaa,
        0 0 70px #419aaa;
    }
  }

  .home-section {
    span {
      display: block;
      font-size: 23px;
      font-weight: 200;
      transform: scale(1, 1);
      transition: all 0.1s ease-in-out;
      cursor: pointer;

      &:hover {
        transform: scale(1.1, 1.1);
        font-size: 25px;
      }

      &.selected {
        font-size: 25px;
        font-weight: bold;
        border-bottom: 2px solid var(--brand-blue);
      }
    }
  }
}

.section-text {
  font-size: 18px;
  line-height: 24px;
}

.content-pad {
  padding: 100px 0px;
}

.animate-in-down-1 {
  opacity: 0;
  transition: all 0.1s ease-in-out;
  animation: slide-down-long 2.2s ease;
  animation-delay: 0.7s;
  animation-fill-mode: forwards;
}
.animate-in-down-2 {
  opacity: 0;
  transition: all 0.1s ease-in-out;
  animation: slide-down-long 1.2s ease;
  animation-delay: 0.6s;
  animation-fill-mode: forwards;
}
.animate-in-down-3 {
  opacity: 0;
  transition: all 0.7s ease-in-out;
  animation: slide-down-long 1s ease;
  animation-delay: 0.5s;
  animation-fill-mode: forwards;
}

.glow {
  font-size: 80px;
  color: #eaeaea;
  text-align: center;
  -webkit-animation: glow 2s ease-in-out infinite alternate;
  -moz-animation: glow 2s ease-in-out infinite alternate;
  animation: glow 2s ease-in-out infinite alternate;
}

@-webkit-keyframes glow {
  from {
    text-shadow:
      0 0 10px rgba(255, 255, 255, 0.5),
      0 0 20px rgba(255, 255, 255, 0.5),
      0 0 30px #419aaa,
      0 0 40px #419aaa,
      0 0 50px #419aaa,
      0 0 60px #419aaa,
      0 0 70px #419aaa;
  }
  to {
    text-shadow:
      0 0 20px rgba(255, 255, 255, 0.5),
      0 0 30px #5bf0ff,
      0 0 40px #5bf0ff,
      0 0 50px #5bf0ff,
      0 0 60px #5bf0ff,
      0 0 70px #5bf0ff,
      0 0 80px #5bf0ff;
  }
}
