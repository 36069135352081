myp-dealer {
  .dealer-img {
    float: right;
    width: 30%;
    margin-left: 20px;
    margin-bottom: 20px;

    @media screen and (max-width: 575px) {
      width: 80%;
      float: none;
      margin: auto;
      display: block;
    }
  }
}
