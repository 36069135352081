myp-exchange,
myp-creator,
exc-browse-exchange {
  .categories {
    list-style: none;
    margin: 0px;
    padding: 0px;

    li {
      padding: 7px 7px;
      display: flex;
      cursor: pointer;
      transition: all 0.1s ease-in-out;
      transform: scale(1, 1);

      &:hover {
        transform: scale(1.04, 1.04);
      }

      &.active {
        background-color: $transBG;
        border-radius: 18px;
      }

      .title {
        text-transform: uppercase;
        font-weight: bold;
        width: 100%;

        img {
          width: 25px;
          margin-left: 5px;
        }
      }

      @media all and (max-width: 1200px) {
        .title {
          font-size: 12px;
        }
      }

      .count {
        background-color: var(--brand-blue);
        color: #eaeaea;
        width: 45px;
        text-align: center;
        margin-left: 10px;
        border-radius: 10px;
        border: 2px solid white;
        font-size: 12px;
        font-weight: bold;
        align-self: center;
      }
    }
  }
  input#exchange_search {
    width: 100%;
    background-color: transparent;
    border: 1px solid rgba(255, 255, 255, 0.5);
    color: #eaeaea;
    outline: none;
    border-radius: 18px;
    padding: 5px;

    &::placeholder {
      color: rgb(177, 177, 177);
    }
  }

  .pagination {
    margin: 10px auto;
    display: inline-block;
    text-align: center;
    width: 100%;

    .show-more {
      font-size: 15px;
      font-weight: bold;
      color: var(--brand-blue);
      display: block;
      cursor: pointer;
      transition: all 0.1s ease-in-out;
    }
  }

  .exchange-creator-fs-details {
    position: fixed;
    z-index: 99999;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(10px);
    overflow: auto;

    .profile-photo {
      width: 200px;
      height: 200px;
      border-radius: 100px;
      box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.5);
    }

    .close-btn {
      position: fixed;
      right: 20px;
      top: 20px;
    }
  }

  // .search-container {
  //   position: absolute;
  //   top: 0px;
  //   width: 100%;
  // }
}

myp-exchange-detail,
exc-exchange-detail {
  .exchange-side-btn {
    margin: 20px 0px;
    font-weight: bold;

    &.download {
      span {
        color: $green;
      }
    }

    &.edit {
      span {
        color: var(--brand-blue);
      }
    }

    &.favorite {
      span {
        color: $orange;
      }

      &.is_favorite {
        span {
          opacity: 0.4;
        }
      }
    }

    &.approve {
      span {
        color: $green;
      }
    }
    &.unapprove {
      span {
        color: red;
      }
    }
  }

  .exc-section {
    margin-bottom: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);

    h2 {
      margin-bottom: 0px;
    }

    .created_by {
      cursor: pointer;
    }
  }

  .preview {
    padding: 10px 10px;
    margin: 5px;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.1);

    audio {
      width: 100%;
    }
  }

  .metadata-table {
    font-size: 12px;
    display: inline;

    @media all and (max-width: 768px) {
      justify-content: center;
    }

    .left {
      padding-right: 4px;
      opacity: 0.62;
    }

    .right {
      text-align: left;
      padding-left: 4px;
    }
  }
}

.drop-zone {
  background-color: #1b1b1b;
  border-radius: 10px;
  margin: 10px 0px;
  padding: 10px 10px;
  text-align: center;
  font-weight: bold;

  .theme_plainlight & {
    background-color: #e0e0e0;
  }

  &.hovering {
    color: #eaeaea;
    background-color: green;
  }
}

.content-drop-zone {
  border: 2px dashed $brand-blue;

  &.hovering {
    color: #eaeaea;
    background-color: green;
  }
}

.uploading-label {
  font-size: 25px;
  color: #c5c5c5;
  position: absolute;
  top: 51%;
  width: 100%;
  left: 0px;
}

myp-search-exchange {
  .autocomplete_container {
    position: relative;

    input {
      background-color: transparent;
      font-size: 20px;
      width: 100%;
      border: none;
      outline: none;
      color: #eaeaea;

      &::placeholder {
        color: rgb(177, 177, 177);
      }
    }

    fa.loader {
      position: absolute;
      right: 10px;
      font-size: 24px;
      top: 8px;
    }

    .autocomplete_results {
      background-color: rgba(0, 0, 0, 0.4);
      padding: 10px;
      margin-top: 5px;
      border-radius: 10px;
      box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.5);
    }
  }
}

.not-approved {
  background-color: red;
  color: #eaeaea;
  font-weight: bold;
  padding: 5px;
  font-size: 14px;
  border-radius: 20px;
}

.unpublished {
  background-color: rgba(255, 255, 255, 0.2);
  color: #eaeaea;
  font-weight: bold;
  padding: 5px 10px;
  font-size: 14px;
  border-radius: 20px;
}

.featured-item {
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  margin-bottom: 50px;
  padding-bottom: 50px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);

  &:hover {
    transform: scale(1.01, 1.01);
  }

  img {
    width: 100%;
    display: block;
    margin: 10px auto;
    max-width: 600px;
  }
}

.audio-previews {
  div.preview {
    margin: 5px 0px;
    padding: 10px;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 5px;
  }
}

.reviews {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  grid-column-gap: 5px;
  grid-row-gap: 5px;

  .review-block {
    cursor: pointer;
    border: 1px solid #0000008c;
    border-radius: 5px;
    padding: 10px;
    text-align: left;
    background-color: rgba(0, 0, 0, 0.4);
    transition: all 0.1s ease-in-out;
    animation: slide-down 0.4s ease;
    display: flex;
    justify-content: space-between;

    .theme_plainlight & {
      background-color: #f5f5f5;
      border: 1px solid #c6c6c6;
    }

    .author-img {
      width: 30px;
      height: 30px;
      border-radius: 15px;
      margin: 0px 5px 5px 0px;
    }

    .author-name {
      font-weight: bold;
      font-size: 15px;
    }

    .comment {
      font-size: 14px;
    }
  }
}

.facet-browser {
  ul {
    margin: 0px;
    padding: 0px;

    li {
      list-style: none;
      padding-bottom: 5px;
      transition: all 0.1s ease-in-out;
      animation: slide-down 0.4s ease;
      cursor: pointer;

      input,
      label {
        cursor: pointer;
      }
      &:hover {
        cursor: pointer;
        transform: scale(1.05, 1.05);
      }
    }
  }
}

#applied-filter-container {
  transition: all 0.1s ease-in-out;
  margin-bottom: 10px;

  .facet-filter {
    animation: slide-down 1s ease;
    background: $solid-fancy-gradient;
    padding: 5px;
    border-radius: 10px;
    margin: 5px;
    display: inline-block;

    fa-icon {
      cursor: pointer;
      margin-left: 5px;
    }
  }
}

.dimmer {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  background-color: rgba(0, 0, 0, 0.6);
}

.exchange-item-profile-photo {
  height: 30px;
  width: 30px;
  border-radius: 15px;
  display: inline-block;
  line-height: 30px;
  margin-right: 15px;
}

myp-exchange-list {
  .exchange-category-filter + .small-btn {
    margin-bottom: 0;
    align-self: flex-end;
  }
}

.exchange-grid-item {
  cursor: pointer;
  background-color: #333;

  .theme_plainlight & {
    background-color: #f5f5f5;
  }

  img {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .name {
    background-color: rgba(0, 0, 0, 0.3);
    padding: 10px;
    backdrop-filter: blur(10px);

    .theme_plainlight & {
      background-color: rgba(255, 255, 255, 0.7);
    }

    h5 {
      margin-bottom: 0px;
    }
  }

  &:hover,
  &:focus {
    color: var(--brand-blue);
  }
}
