$break-small: 767px;
$break-fullwidth: 575px;
$break-tiny: 460px;

myp-download-tile,
myp-product-tile,
.item-tile {
  cursor: pointer;
  border-radius: 10px;
  padding-top: 10px;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  background-color: #232323;
  justify-content: space-around;
  transition: all 0.1s ease-in-out;
  // animation: slide-down 0.4s ease;
  // border: 1px solid #0000008c;
  // box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);

  .theme_plainlight & {
    background-color: #f5f5f5;
    // border: 1px solid #c6c6c6;
    // box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  }

  .card {
    &.product-card {
      background-color: inherit;
      border: none;
      border-radius: 10px;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }

  .tile-action-btn {
    font-size: 19px;
  }

  &:hover {
    transform: scale(1.01, 1.01);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  }

  .extra-info {
    display: block;
    line-height: 17px;
    font-size: 13px;
    align-self: flex-end;
  }

  .tile-data {
    width: 100%;
    margin-bottom: 10px;
    align-self: flex-end;
  }

  .downloads_header {
    width: 100%;
    padding: 0px 10px;

    h4 {
      font-size: 15px;
      margin-bottom: 0px;
    }
  }

  .tile-bottom {
    display: flex;
    // background-color: rgba(255, 255, 255, 0.1);
    font-size: 12px;
    align-items: center;
    margin-top: 10px;
    border-radius: 0px 0px 10px 10px;
    height: 35px;
    align-self: flex-end;

    .theme_plainlight & {
      // background-color: rgba(0, 0, 0, 0.05);
    }

    .buttons {
      // width: 30%;
      padding: 5px;
      position: relative;
      display: flex;
      align-items: center;

      &.left {
        justify-content: flex-start;
      }
      &.right {
        justify-content: flex-end;
        margin-right: 10px;
      }
    }
  }

  img.product-image {
    max-height: 200px;
    max-width: 90%;
    height: auto;
    margin: 10px 0px;
    align-self: flex-end;
  }

  .downloads_details {
    width: 100%;
    flex: 1;
    margin-bottom: 10px;

    small {
      display: block;
      opacity: 0.6;
      font-size: 12px;

      &.filename {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }

  .downloads_button {
    margin-top: 10px;
    width: 100%;
    align-self: flex-end;
  }
}
