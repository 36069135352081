.connect-home {
  background-size: cover;
  background-position: left center;

  .connect-bg-container {
    overflow: hidden;
    position: relative;
    width: 65%;
    height: 100%;
    background-size: cover;
    background-position: right center;
    @media screen and (max-width: $break-small) {
      display: none;
    }

    .video-background {
      position: relative;
      width: 100%; /* Adjust to fit the container */
      height: 100%; /* Adjust based on your requirements */
      overflow: hidden;
    }
    video {
      position: absolute;
      top: 0;
      right: -200px;
      width: calc(100% + 200px);
      height: 100%;
      object-fit: cover;
      object-position: right;
    }
  }

  .connect-bg-container.s17 {
    background-position: center;
  }

  .connect-ui-container {
    height: 100%;
    overflow: scroll;
    width: 35%;
    min-width: 500px;
    background-color: $brand-dark;

    .theme_plainlight & {
      background-color: white;
    }

    @media screen and (max-width: $break-small) {
      min-width: 100%;
      width: 100%;
    }

    .connect-login-container {
      padding: 20px;
      width: 100%;
      display: flex;
      flex-direction: column;
      height: 100%;
      max-width: 500px;
      margin: auto;

      .input-container {
        margin-bottom: auto;
      }

      img.login-logo {
        width: 300px;
        margin: auto auto 40px auto;
        display: block;
      }

      img.login-logo-demo {
        width: 80%;
        margin: auto auto 40px auto;
        display: block;
      }

      .below-title {
        letter-spacing: 15px;
        background: -webkit-linear-gradient(#fff, #656261);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        margin: 20px 0px;
      }

      .form {
        max-width: 600px;
        margin: auto;
      }

      input {
        cursor: pointer;
      }

      .double-input {
        display: flex;
        margin: 20px 0px;
        input:first-child {
          margin-right: 5px;
        }
        input:last-child {
          margin-left: 5px;
        }
      }

      .single-input {
        margin: 20px 0px;
      }

      .password-input {
        position: relative;
        margin: 20px 0px;

        fa-icon {
          position: absolute;
          top: 0px;
          right: 30px;
          height: 100%;
          display: flex;
          align-items: center;
        }
      }

      .country-input {
        position: relative;
        input {
          padding-left: 40px !important;
        }
        div.flag {
          position: absolute;
          height: 100%;
          display: flex;
          align-items: center;
          left: 15px;
          top: 0px;
          width: 100%;
          cursor: pointer;
        }
      }

      input[type="email"],
      input[type="password"],
      input.login-input {
        padding: 15px;
        border-radius: 30px;
        background-color: rgba(255, 255, 255, 0.1);
        display: block;
        width: 100%;
        border: none;
        color: white;
        outline: none;

        .theme_plainlight & {
          background-color: #f5f5f5;
          color: black;
        }
      }
    }

    .login-error {
      font-weight: bold;
      padding: 10px;
      border-radius: 20px;
      border: 2px solid red;
      background-color: rgba(0, 0, 0, 0.2);
      margin: 20px 0px;
    }
  }
}

.api-error-message {
  font-weight: bold;
  padding: 10px;
  border-radius: 20px;
  border: 2px solid red;
  background-color: rgba(0, 0, 0, 0.2);
  margin: 20px 0px;
}
