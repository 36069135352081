@font-face {
  font-family: "Helvetica Now Bold";
  src: url("../../../../assets/fonts/helvetica-now/bold.otf") format("opentype");
}

@font-face {
  font-family: "Helvetica Now XBold";
  src: url("../../../../assets/fonts/helvetica-now/xbold.otf") format("opentype");
}

@font-face {
  font-family: "Helvetica Now";
  src: url("../../../../assets/fonts/helvetica-now/normal.otf") format("opentype");
}

@font-face {
  font-family: "Helvetica Now Medium";
  src: url("../../../../assets/fonts/helvetica-now/med.otf") format("opentype");
}

@font-face {
  font-family: "Helvetica Now Black";
  src: url("../../../../assets/fonts/helvetica-now/black.otf") format("opentype");
}

@font-face {
  font-family: "Helvetica Now XBlack";
  src: url("../../../../assets/fonts/helvetica-now/xblack.otf") format("opentype");
}

@font-face {
  font-family: "Helvetica Now Light";
  src: url("../../../../assets/fonts/helvetica-now/light.otf") format("opentype");
}

.brandfont-bold {
  font-family: "Helvetica Now Bold";
}

.brandfont-xbold {
  font-family: "Helvetica Now XBold";
}

.brandfont {
  font-family: "Helvetica Now";
}

.brandfont-medium {
  font-family: "Helvetica Now Medium";
}

.brandfont-light {
  font-family: "Helvetica Now Light";
}

.brandfont-black {
  font-family: "Helvetica Now Bold";
}

.brandfont-xblack {
  font-family: "Helvetica Now XBlack";
}
