.modal-purchase-container {
  .modal-purchase {
    display: flex;

    @media all and (max-width: 991px) {
      flex-wrap: wrap;
    }

    myp-checkout-product-details {
      @media all and (max-width: 991px) {
        width: 100%;
      }
    }

    .order-details {
      min-width: 200px;
      max-width: 380px;
      padding-right: 40px;

      @media all and (max-width: 991px) {
        width: 100% !important;
        padding: 0px 15px;
        max-width: 100%;
      }

      .option {
        @media all and (max-width: 991px) {
          display: flex;
          justify-content: center;
          flex-wrap: wrap;
        }
      }

      img {
        width: 100%;
        @media all and (max-width: 991px) {
          width: 80%;
          align-self: baseline;
          margin-right: 20px;
          margin-top: 20px;
        }
      }

      .total-amount-charged {
        font-size: 12px;
      }
    }

    .order-form,
    .confirm-purchase {
      width: 100%;

      .plan-option {
        cursor: pointer;
        padding: 10px;
        background-color: rgba(255, 255, 255, 0.1);
        border-radius: 20px;
        border: 2px solid transparent;
        .theme_plainlight & {
          background-color: rgba(0, 0, 0, 0.1);
        }

        &.selected {
          background: linear-gradient(45deg, rgba(0, 131, 255, 0.11) 0%, rgba(251, 253, 255, 0.02) 57%, rgba(255, 255, 255, 0.02) 58%);
          border: 2px solid var(--brand-blue);
        }

        .discounted-price {
          color: var(--brand-blue);
        }
      }

      .logo {
        max-width: 200px;
      }

      @media all and (max-width: 576px) {
        padding-bottom: 100px;
      }
      .feature-column-container {
        display: flex;
        @media all and (max-width: 991px) {
          flex-wrap: wrap;
        }

        .feature-column {
          flex: 1;
          @media all and (max-width: 991px) {
            width: 100%;
          }
          ul {
            &.feature-items {
              column-count: 2;
              column-gap: 40px;
              margin-top: 0px;
              padding-left: 15px;
            }
          }
        }
      }

      .plan-chooser {
        overflow: hidden;

        input[type="radio"] {
          position: absolute;
          clip: rect(0, 0, 0, 0);
          pointer-events: none;
        }
        label {
          &.plan-option {
            width: 100%;
            cursor: pointer;
            padding: 10px;
            background-color: rgba(255, 255, 255, 0.1);
            border-radius: 20px;
            border: 2px solid transparent;
            margin-bottom: 0;
            .theme_plainlight & {
              background-color: rgba(0, 0, 0, 0.1);
            }

            &.selected {
              background: linear-gradient(45deg, rgba(0, 131, 255, 0.11) 0%, rgba(251, 253, 255, 0.02) 57%, rgba(255, 255, 255, 0.02) 58%);
              border: 2px solid var(--brand-blue);
              outline: none;
            }

            .discounted-price {
              color: var(--brand-blue);
            }
          }
        }
      }
    }
  }
}

.feature-column-container {
  display: flex;
  @media all and (max-width: 991px) {
    flex-wrap: wrap;
  }

  .feature-column {
    flex: 1;
    @media all and (max-width: 991px) {
      width: 100%;
    }
  }
}

.secure-transaction-badge {
  font-size: 12px;
}

.buy-button-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .mobile-total {
    display: none;
    font-size: 12px;
    @extend .brandfont-bold;
    margin-bottom: 10px;
  }

  .btn-primary {
    margin-right: 10px;
    &:disabled {
      cursor: not-allowed;
    }
  }

  .secure-transaction-badge {
    margin-right: 20px;
  }
  @media all and (max-width: 576px) {
    display: flex;
    justify-content: right;
    position: fixed;
    flex-direction: column-reverse;
    width: 100%;
    left: 0px;
    bottom: 0px;
    padding: 10px 0px;
    background-color: black;
    .secure-transaction-badge {
      margin-right: 0px;
    }

    .btn-primary {
      margin-right: 0px;
    }

    .mobile-total {
      display: block;
    }

    .theme_plainlight & {
      background-color: white;
    }

    .btn {
      margin-bottom: 10px;
    }
  }
}

.best-value {
  padding: 3px 8px;
  font-size: 10px;
  @extend .brandfont-black;
  background-color: var(--brand-blue);
  border-radius: 10px;
  color: white;
}

.monthly-gradient {
  background: -webkit-linear-gradient(left, #00f0ff, #1c8efb);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
}

.hybrid-gradient {
  background: -webkit-linear-gradient(left, #ff6153, #ff1391);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
}
