// shared across all platforms and apps
// @import '~@mypxplat/xplat-scss/index';

/**
 * The following are web specific (used with any web app targets)
 */

@import url("https://fonts.googleapis.com/css?family=Lato");

// web specific variables
@import "variables";

// web styles (create/import other scss files or define as needed)
@import "spacing";
@import "tags";
@import "header";
@import "buttons";
@import "products";
@import "exchange";
@import "home";
@import "exchangehome";
@import "footer";
@import "orders";
@import "dealer";
@import "support";
@import "files";
@import "learn";
@import "createticket";
@import "modals";
@import "video";
@import "downloadtile";
@import "editprofile";
@import "notification";
@import "overrides";
@import "cloud";
@import "cloudwelcome";
@import "actionchooser";
@import "audioplayer";
@import "filedetail";
@import "comments";
@import "onboarding";
@import "themes";
@import "community";
@import "metro";
@import "algolia";
@import "uikit";
@import "connect";
@import "fonts";
@import "switch";
@import "purchase";
@import "quill";
@import "admin";
@import "skeletonloaders";
.btn {
  cursor: pointer;
}

.hover-zoom {
  transition: all 0.1s ease-out;
  cursor: pointer;

  &:hover {
    transform: scale(1.02) !important;
  }
}

.hover-zoom-sm {
  transition: all 0.1s ease-out;
  cursor: pointer;

  &:hover {
    transform: scale(1.02) !important;
  }
}

.image-border {
  border-radius: 5px;
  // border: 1px solid #0000008c;
  // box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.4);

  &.round {
    border-radius: 500px;
  }

  &.sm {
    width: 50px;
    height: 50px;
  }

  &.md {
    width: 100px;
    height: 100px;
  }

  &.lg {
    width: 150px;
    height: 150px;
  }
}

.fs-header-background {
  position: absolute;
  height: 300px;
  width: 100%;
  top: 0px;
  background-size: cover;
  background-position: center center;
  z-index: -1;
}

.list-item:hover + .list-item {
  .list-item-container {
    border-top: 1px solid transparent;
  }
}

.list-item {
  display: block;
  width: 100%;
  transition: all 0.1s ease-in-out;
  transform: scale(1, 1);

  .collapse-icon {
    margin-right: 10px;
    width: 30px;
    height: 30px;
    @extend .small-btn;
    font-size: 18px;
    align-self: flex-start;
  }

  .collapsible {
    border-bottom: 1px solid var(--soft-border);
    padding: 10px;
    transition: all 0.1s ease-in-out;
  }

  .item-status {
    font-size: 12px;
    padding: 5px;
    border-radius: 10px;
    border: 1px solid white;
    background-color: gray;
    &.online {
      background-color: $green;
    }
    &.pending {
      background-color: $orange;
    }
    &.new {
      background-color: $orange;
    }
    &.open {
      background-color: $orange;
    }
    &.solved {
      background-color: $green;
    }
  }

  &.show-background {
    .list-item-container {
      background: #232323;

      border-radius: 10px 10px 10px 10px;
      .theme_plainlight & {
        background: #f5f5f5;
      }
    }

    .collapsible {
      border-radius: 0px 0px 10px 10px !important;
      background: #232323;
      border-bottom: none;

      .theme_plainlight & {
        background: #f5f5f5;
      }
    }
  }

  &.embedded-in-collapsible {
    .list-item-container {
      background: #383838 !important;

      .theme_plainlight & {
        background: rgba(234, 234, 234) !important;
      }
    }
  }

  &.hover:hover {
    transform: scale(1.02, 1.02);
    cursor: pointer;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    &.dontgrow {
      transition: none;
      transform: none;
    }

    .collapsible {
      border-radius: 0px 0px 10px 10px !important;
      background: #232323;
      border-bottom: none;
      .theme_plainlight & {
        background: #f5f5f5;
      }
    }

    .list-item-container {
      background: #232323;
      text-decoration: none;
      border-top: 1px solid transparent;
      border-bottom: 1px solid transparent;
      border-radius: 10px;
      &.expanded {
        border-radius: 10px 10px 0px 0px !important;
        border-bottom: 1px solid var(--soft-border);
      }
      .theme_plainlight & {
        background: #f5f5f5;
      }
    }
  }

  &:first-child {
    .list-item-container {
      border-top: 1px solid transparent !important;
    }
  }

  &.bottom-border {
    .list-item-container {
      border-bottom: 1px solid rgba(255, 255, 255, 0.2) !important;

      .theme_plainlight & {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
      }
    }
  }

  .list-item-container {
    transition: all 0.1s ease-in-out;
    display: flex;
    padding: 10px;
    border-top: 1px solid $transBG;
    border-bottom: 1px solid transparent;
    align-items: center;
    // cursor: pointer;
    justify-content: space-between;
    position: relative;

    &.expanded {
      border-radius: 10px 10px 0px 0px !important;
      border-bottom: 1px solid var(--soft-border);
    }

    &.align-top {
      align-items: flex-start;
    }

    .theme_plainlight & {
      border-top: 1px solid rgba(0, 0, 0, 0.1);
    }

    &.no-border {
      border-bottom: 1px solid transparent;
    }

    &.not-selectable {
      cursor: auto;
    }

    .close-btn {
      position: absolute;
      top: -5px;
      right: -5px;
    }

    .item-image {
      font-size: 40px;
      margin-right: 10px;
      align-self: baseline;

      &.align-center {
        align-self: center;
      }
    }

    img.item-image {
      width: 40px;
      // height: 40px;
      flex-shrink: 0;

      &.large {
        width: 100px;
        height: auto;
      }

      &.round {
        border-radius: 20px;
      }

      &.not-square {
        height: auto !important;
      }
    }

    h3 {
      font-size: 1rem;
    }

    .title {
      display: block;
      font-weight: bold;
    }

    .main-data {
      line-height: 18px;
      margin-right: 10px;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      overflow: hidden;
      // @media screen and (max-width: 900px) {
      //   margin: 10px;
      // }

      h6,
      h3 {
        // @extend .gothic;
        font-weight: bold;
        margin-bottom: 0px;
      }
    }

    .sub-data {
      font-size: 13px;
      display: inline-block;
      line-height: 17px;

      .description {
        max-height: 70px;
        overflow: hidden;

        &.short {
          max-height: 34px;
        }
      }

      .timestamp {
        color: var(--brand-blue);

        .theme_plainlight & {
          color: var(--brand-blue);
        }
      }
    }

    .metadata {
      font-size: 13px;
      display: flex;
      flex-wrap: wrap;

      .tag {
        display: flex;
        // padding: 4px 9px;
        margin: 5px 15px 0px 0px;

        .title {
          opacity: 0.5;
          margin-right: 5px;
        }

        .value {
          font-weight: bold;
        }
      }
    }

    fa-icon.list-item-icon {
      font-size: 28px;
      margin-right: 10px;
      margin-left: 10px;
    }

    .button-content {
      display: flex;
      align-items: center;
      margin-left: auto;
      margin-right: 5px;
    }

    .right-data-buttons {
      margin-left: auto;
      display: flex;
      margin-right: 5px;
      align-self: center;
      align-items: center;
      cursor: pointer;
      .right-data-list {
        line-height: 18px;
        font-size: 12px;
      }
      fa-icon {
        transition: all 0.1s ease-in-out;
        &:hover {
          color: var(--brand-blue);
        }
      }
      &.no-hover {
        fa-icon {
          &:hover {
            color: #eaeaea !important;

            .theme_plainlight & {
              color: #121212 !important;
            }
          }
        }
      }
    }

    .buttons {
      margin-left: auto;
      align-self: center;
      margin-right: 5px;
      display: flex;

      .btn {
        margin: 0px 3px;
      }
    }

    .pill {
      font-size: 12px;
    }
  }

  &.folder {
    &:hover {
      .list-item-container {
        background-color: var(--brand-blue) !important;
        border: 3px solid var(--brand-blue) !important;
        border-top: 3px solid var(--brand-blue) !important;
      }
    }

    .list-item-container {
      border: 3px solid var(--brand-blue);
      border-radius: 10px;
      margin-bottom: 10px;
    }

    &:first-child {
      .list-item-container {
        border-top: 3px solid var(--brand-blue) !important;
      }
    }
  }

  &.video {
    .list-item-container {
      @media screen and (max-width: 470px) {
        flex-direction: column;
      }

      .content & {
        @media screen and (min-width: 601px) and (max-width: 775px) {
          flex-direction: column;
        }
      }
    }

    img.item-image {
      width: 100px;
      height: 60px;

      @media screen and (max-width: 470px) {
        margin-right: 0px;
        margin-left: 0px;
        margin-bottom: 10px;
        width: 100%;
        height: auto;
      }
      .content & {
        @media screen and (min-width: 601px) and (max-width: 775px) {
          margin-right: 0px;
          margin-left: 0px;
          margin-bottom: 10px;
          width: 100%;
          height: auto;
        }
      }
    }

    .main-data {
      @media screen and (max-width: 470px) {
        margin-right: 0px;
      }
      .content & {
        @media screen and (min-width: 601px) and (max-width: 775px) {
          margin-right: 0px;
        }
      }
    }

    .progress-indicator {
      height: 4px;
      background-color: var(--brand-blue);
      margin-bottom: 5px;
    }
  }

  &.large {
    img.item-image {
      width: 150px;
      height: auto;

      @media screen and (max-width: 470px) {
        margin-right: 0px;
        margin-left: 0px;
        margin-bottom: 10px;
        width: 100%;
        height: auto;
      }

      @media screen and (min-width: 1100px) {
        width: 200px;
      }
    }

    .main-data {
      h6 {
        font-size: 20px;

        @media screen and (min-width: 1100px) {
          font-size: 24px;
        }
      }
    }
  }
}

.list-item.folder:hover + .list-item.folder {
  .list-item-container {
    border-top: 3px solid var(--brand-blue) !important;
  }
}

myp-firmware-instructions {
  img {
    max-width: 100%;
  }
}

.animate-in-down {
  transition: all 0.1s ease-in-out;
  animation: slide-down 0.4s ease;
}

.animate-in-from-right {
  transition: all 0.1s ease-in-out;
  animation: slide-over-from-right 0.4s ease;
}
.animate-in-from-left {
  transition: all 0.1s ease-in-out;
  animation: slide-over-from-left 0.4s ease;
}

.overlay-close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
}

body.disable-scroll {
  overflow: hidden !important;
}

.notification-container {
  position: absolute;
  top: 30px;
  z-index: 99999;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  transform: translate(0px, -200px);

  .message-container {
    width: 500px;
    max-width: 95%;
    border-radius: 20px;
    background-color: rgba(43, 43, 43, 0.99);
    box-shadow: 0px 0px 15px #fafad278;
    padding: 10px;
    display: flex;
    align-items: center;
    border: 3px solid black;

    .text {
      strong {
        font-size: 14px;
      }

      text-align: left;
    }

    img {
      width: 40px;
      height: 40px;
      border-radius: 20px;
      margin-right: 10px;
    }
  }
}

.section-header {
  display: block;
  font-size: 22px;
  font-weight: bold;
  width: 100%;
  padding: 10px 0px;
  display: flex;
  justify-content: space-between;
  @extend .brandfont-bold;

  &.force-dark {
    box-shadow: none;
    color: #eaeaea;
  }

  .section-header-link {
    cursor: pointer;
    font-size: 14px;
    transition: all 0.1s ease-out;

    &:hover {
      color: var(--brand-blue);
    }
  }
  &.red {
    background-color: $pink;
    display: inline-block;
    width: auto;
    padding: 10px;
    border-radius: 10px;

    .theme_plainlight & {
      color: #eaeaea;
    }
  }
}

.subsection-header {
  font-size: 1.25rem !important;
}

.list-tags {
  .list-tag-item {
    background-color: rgba(255, 255, 255, 0.1);
    padding: 3px 8px;
    border-radius: 10px;
    margin: 2px 3px;
    font-size: 16px;
    @extend .brandfont-bold;

    fa-icon {
      cursor: pointer;
    }

    .theme_plainlight & {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }
}

.lighter-link {
  a {
    color: #268bf7;
  }
}

section {
  &.home-section {
    .section-header {
      width: 100%;
      padding: 10px 0px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      @extend .brandfont-bold;

      &.force-dark {
        box-shadow: none;
        color: #eaeaea;
      }

      h4 {
        &.section-title {
          font-size: 22px;
          font-weight: bold;
          margin-bottom: 0px;
        }
      }

      .section-header-link {
        cursor: pointer;
        font-size: 14px;
        transition: all 0.1s ease-out;

        &:hover {
          color: var(--brand-blue);
        }
      }
      &.red {
        background-color: $pink;
        display: inline-block;
        width: auto;
        padding: 10px;
        border-radius: 10px;

        .theme_plainlight & {
          color: #eaeaea;
        }
      }
    }
    .card {
      &.register-product-card,
      &.product-card {
        background-color: inherit;
        border: none;
        border-radius: 10px;
        padding: 10px 0 0 0;
      }
      &.register-product-card {
        padding: 20px;
      }
    }
    .video-grid-item {
      .video-metadata {
        button fa-icon {
          color: var(--brand-blue);
        }
      }
    }
  }
}

// ul {
//   &.grid-container > li {
//     border-radius: 12px;
//     margin-right: 0px;
//   }
// }
