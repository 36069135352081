.ais-SearchBox {
  margin-bottom: 1em;
}

.community-search-results {
  .result-item {
    margin: 20px;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 20px;
  }
  em {
    background-color: #007bff;
    color: #eaeaea;
    font-weight: bold;
    border-radius: 3px;
    font-style: normal;
  }
}
