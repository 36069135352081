.file-detail-page {
  .multiple {
    cursor: pointer;
  }

  .stem-selector {
    background-color: rgba(0, 0, 0, 0.9);
    border-radius: 10px;
    box-shadow: 0px 0px 10px black;
    z-index: 99999;
    text-align: center;
    top: 30px;

    ul {
      padding: 0px;
      margin: 0px;

      li {
        list-style: none;
        @extend .brandfont-bold;
        color: #eaeaea;
        cursor: pointer;
        padding: 15px 10px;
        text-align: center;

        &.selected {
          color: $pink;
          background-color: rgba(255, 255, 255, 0.1);
        }
        &:hover {
          background-color: rgba(255, 255, 255, 0.2);
          color: $pink;
        }

        .stem-comment-count {
          color: gray;
          font-size: 11px;
          line-height: 13px;
          display: block;
          // padding: 5px;
          // background-color: var(--brand-blue);;
          // width: 26px;
          // height: 26px;
          // display: inline-block;
          // line-height: 16px;
          // border-radius: 13px;
          // color: #eaeaea;
        }
      }
    }
  }
}

#file-waveform {
  position: relative;

  .timecode-container {
    width: 100%;
    height: 15px;
    position: absolute;
    top: -20px;
    font-size: 11px;
    color: var(--brand-blue);

    @media screen and (max-width: 575px) {
      width: 98%;
    }

    .timecode {
      position: absolute;
      display: inline-block;
      width: 40px;
      text-align: center;
      margin-left: -20px;

      &::before {
        content: "|";
        display: block;
        position: absolute;
        top: 10px;
        color: #eaeaea;
        left: 50%;
        width: 1;
      }

      &:first-child {
        text-align: left;
        margin-left: 0px;

        &:before {
          left: auto;
          left: 1px;
        }
      }

      &:last-child {
        text-align: right;
        margin-left: -40px;

        &:before {
          left: auto;
          right: 1px;
        }
      }
    }
  }

  .comment-head-container {
    width: 20px;
    height: 20px;
    margin-left: -10px;
    position: absolute;
    bottom: -25px;
    cursor: pointer;
    transition: all 0.1s ease-in-out;

    .comment-head {
      width: 20px;
      height: 20px;
      border-radius: 10px;
      transition: all 0.1s ease-in-out;
    }

    &.focused {
      width: 50px;
      height: 50px;
      margin-left: -25px;
      border-radius: 25px;
      bottom: -50px;
      z-index: 999;

      .comment-head {
        border: 2px solid red;
        width: 50px;
        height: 50px;
        border-radius: 25px;
      }
    }

    &::before {
      content: "|";
      display: block;
      position: absolute;
      top: -18px;
      color: #e51a89;
      left: 50%;
      width: 5px;
      margin-left: -2.5px;
    }
  }

  .audio-comment {
    background-color: rgba(0, 0, 0, 0.9);
    padding: 10px;
    position: absolute;
    top: 158px;
    z-index: 1049;
    border-radius: 10px;
    width: 250px;
    box-shadow: 0px 0px 13px black;
    transition: all 0.1s ease-in-out;
    animation: slide-up 0.4s ease;
    margin-left: -125px;

    .theme_plainlight & {
      background-color: rgba(255, 255, 255, 0.9);
    }

    &::before {
      content: "▴";
      display: block;
      font-weight: bold;
      font-size: 28px;
      position: absolute;
      top: -32px;
      color: #e51a89;
      left: 50%;
      width: 20px;
      margin-left: -10px;
      text-align: center;
    }

    .audio-comment-messages {
      max-height: 265px;
      overflow: auto;

      .main-comment {
        padding: 5px;
        background-color: rgba(255, 255, 255, 0.1);
        border-radius: 5px;

        .theme_plainlight & {
          background-color: rgba(0, 0, 0, 0.1);
        }
      }
    }
  }

  .audio-comment-input {
    background-color: rgba(255, 255, 255, 0.2);
    font-size: 13px;
    border-radius: 10px;
    border: none;
    width: 100%;
    padding: 5px;
    color: #eaeaea;
    outline: none;

    .theme_plainlight & {
      background-color: rgba(0, 0, 0, 0.2);
      color: black;
    }
  }

  #add-audio-comment {
    position: absolute;
    top: -50px;
    width: 200px;
    background-color: black;
    z-index: 99999;
    border-radius: 10px;
    padding: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.6);
    margin-left: -100px;
    transition: all 0.1s ease-in-out;
    animation: slide-up 0.4s ease;

    .theme_plainlight & {
      background-color: white;
    }
  }
}

.waveform-play {
  position: absolute;
  bottom: 10px;
  left: 10px;
  z-index: 2;

  .waveform-play-btn {
    width: 40px;
    height: 40px;
    text-align: center;
    font-size: 18px;
    background-color: var(--brand-blue);
    border-radius: 20px;
    display: inline-block;
    line-height: 40px;
    color: #eaeaea;
    cursor: pointer;
    transition: all 0.1s ease-in-out;
    transform: scale(1, 1);
    &:hover {
      transform: scale(1.2, 1.2);
    }
  }
}

.top-right-close {
  position: absolute;
  top: -10px;
  right: -10px;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  color: #eaeaea;
  background-color: black;
  border: 1px solid rgba(255, 255, 255, 0.5);
  font-size: 12px;
  text-align: center;
  box-shadow: 2px -1px 6px rgba(0, 0, 0, 0.9);
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  transform: scale(1, 1);
  &:hover {
    transform: scale(1.1, 1.1);
  }
}

myp-message-input {
  width: 100%;
}
