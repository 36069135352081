canvas#c {
  display: block;
  width: 100vw;
  height: 100vh;
}

.welcome {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
  top: 0px;
  left: 0px;

  span.continue {
    padding: 10px;
    color: #eaeaea;
    cursor: pointer;
    display: inline-block;
    font-family: $main-font;
    font-size: 18px;
    letter-spacing: 10px;

    &.border {
      border: 1px solid white;
      border-radius: 5px;
    }

    fa-icon {
      font-size: 14px;
    }
  }

  &.welcome1 {
    h2,
    h1 {
      opacity: 0;
      letter-spacing: 15px;
      text-align: center;
    }

    h2 {
      @media screen and (max-width: $break-small) {
        font-size: 18px !important;
      }
    }

    h1 {
      font-size: 65px;
      background: -webkit-linear-gradient(#fff, #656261);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;

      @media screen and (max-width: $break-small) {
        margin-top: 30px;
        font-size: 30px !important;
      }
    }

    .image-container {
      margin-bottom: 30px;
      width: 80%;
      margin: -100px 0px -30px -0px;
      max-width: 550px;
      position: relative;

      img {
        width: 100%;
      }

      img.planet,
      img.flare,
      img.bokeh {
        opacity: 0;
      }

      img.flare {
        position: absolute;
        top: 0px;
        left: 0px;
      }

      img.bokeh {
        position: absolute;
        left: 0px;
        top: 0px;
      }
    }

    .continue {
      opacity: 0;
      margin-top: 50px;
    }
  }

  .step-container {
    // opacity: 0;
  }
}

.signup-flow,
.welcome,
.onboarding-step {
  .plan-options,
  .account-type-options {
    padding: 20px 0px 0px 0px !important;
    justify-content: space-between;

    .option {
      transition: all 0.1s ease-in-out;
      transform: scale(1, 1);
      min-width: 45%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 40px 40px;
      border-radius: 30px;
      cursor: pointer;
      height: 100%;
      background-color: #242525;
      border: 2px solid #242525;

      &:hover {
        background: linear-gradient(45deg, rgba(0, 131, 255, 0.11) 0%, rgba(251, 253, 255, 0.02) 57%, rgba(255, 255, 255, 0.02) 58%);
        border: 2px solid var(--brand-blue);
        box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.3);

        .brand-btn-reverse {
          background-color: var(--brand-blue);
          color: white;
          border: 2px solid var(--brand-blue);
        }

        .theme_plainlight & {
          background: linear-gradient(45deg, rgba(0, 131, 255, 0.11) 0%, rgba(251, 253, 255, 0.02) 57%, rgba(255, 255, 255, 0.02) 58%);
          border: 2px solid var(--brand-blue);
          box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.3);
        }
      }

      .theme_plainlight & {
        border: 2px solid #f5f5f5;
        background-color: #f5f5f5;
      }

      img {
        width: 80%;
        max-width: 200px;
        margin: 0px auto 10px auto;
      }

      .plan-title {
        font-size: 30px;

        @media screen and (max-width: $break-small) {
          font-size: 20px;
        }
      }

      &.selected {
        background: linear-gradient(45deg, rgba(0, 131, 255, 0.11) 0%, rgba(251, 253, 255, 0.02) 57%, rgba(255, 255, 255, 0.02) 58%);
        border: 2px solid var(--brand-blue);
        box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.3);

        .theme_plainlight & {
          background: linear-gradient(45deg, rgba(0, 131, 255, 0.11) 0%, rgba(251, 253, 255, 0.02) 57%, rgba(255, 255, 255, 0.02) 58%);
          border: 2px solid var(--brand-blue);
          box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.3);
        }
      }
    }
  }
}
.onboarding-step {
  .account-type-options {
    .option {
      justify-content: space-between;
      .plan-title {
        font-size: 20px;
      }
    }
  }
}

.step-counter {
  display: flex;
  margin-bottom: 30px;
  border-radius: 5px;

  .step {
    opacity: 0.4;
    width: 100%;
    text-align: center;
    font-family: $main-font;
    font-weight: bold;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:last-child {
      border: none;
    }

    &.selected {
      opacity: 1;
      border-bottom: 2px solid white;

      .theme_plainlight & {
        border-bottom: 2px solid #333;
      }
    }
  }
}
.onboarding-step {
  animation: slide-over-from-right 0.4s ease;
  padding: 0px 10px;

  a {
    font-weight: bold;
    &:hover {
      color: #eaeaea;
    }
  }

  h1 {
    font-size: 32px;
  }

  .credit-card-info {
    text-align: left;
  }
}

.onboard-error {
  color: red;
}

.onboard-helptext {
  padding: 10px;
  border-radius: 10px;
  background-color: #0a040473;
  color: #eaeaea;
  border: 4px solid rgb(207 207 88);
}

.coupon-success {
  padding: 10px;
  border-radius: 10px;
  background-color: #0a040473;
  color: #eaeaea;
  border: 4px solid green;
}

.onboarding-go-btn {
  border: 2px solid white;
  font-size: 22px;
  font-weight: bold;
  border-radius: 5px;
  padding: 8px 50px;
  background-color: transparent;
  color: #eaeaea;
  @extend .brandfont-bold;
  transition: all 0.1s ease-in-out !important;

  &:hover {
    transform: scale(1.05, 1.05);
    color: #eaeaea;
    text-decoration: none;
  }

  &.disabled {
    opacity: 0.5;
  }
}
