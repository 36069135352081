myp-ticket-list-item {
  display: block;
  width: 100%;
  transition: all 0.1s ease-in-out;
  transform: scale(1, 1);

  &:hover {
    transform: scale(1.02, 1.02);
  }

  .ticket-list-item-container {
    transition: all 0.1s ease-in-out;
    display: flex;
    padding: 10px 5px;
    border-bottom: 1px solid $transBG;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    &:hover {
      background: rgba(0, 0, 0, 0.5);
      border-radius: 5px;
      border: 1px solid black;
      box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.7);
    }

    .sub-data {
      color: var(--brand-blue);
      font-size: 13px;
      display: inline-block;
      line-height: 17px;
    }
  }
}
