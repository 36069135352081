/**
 * The following are shared across all platforms and apps
 */

// shared variables
@import "variables";

// @import "~animate.css/animate.min";

.animated.delay-d1s {
  animation-delay: 0.1s;
}
.animated.delay-d2s {
  animation-delay: 0.2s;
}
.animated.delay-d3s {
  animation-delay: 0.3s;
}
.animated.delay-d4s {
  animation-delay: 0.4s;
}
.animated.delay-d5s {
  animation-delay: 0.5s;
}
.animated.delay-d6s {
  animation-delay: 0.6s;
}
.animated.delay-d7s {
  animation-delay: 0.7s;
}
.animated.delay-d8s {
  animation-delay: 0.8s;
}
.animated.delay-d9s {
  animation-delay: 0.9s;
}

// shared mixins

// shared utilities

// create/import other scss files or define as needed...
@import "ticket-list-item";

body {
  font-family: "Helvetica Now", sans-serif;
}

Page {
  font-family: system;
}

/* Buttons */
.spinnyBtn Button,
.spinnyBtn span.button,
.spinnyBtn fa.button {
  cursor: pointer;
  border-color: rgba(255, 255, 255, 0.5);
  border-width: 1px;
  border-radius: 5pt;
  border-style: solid;
  color: #eaeaea;
  font-weight: bold;
  padding: 7pt;
  background-color: rgba(255, 255, 255, 0.2);
  font-size: 14pt;
  margin-top: 10pt;
  display: block;
  margin: auto;
  text-align: center;
}

.bare_btn {
  color: var(--brand-blue) !important;
  font-family: "Lato", "Open Sans", Arial, sans-serif;
  cursor: pointer;

  &.sm {
    font-size: 14px;
  }
}
.menu-btn {
  display: inline-block;
  cursor: pointer;
  color: #eaeaea;
  font-weight: bold;
  font-size: 13px;
  text-align: center;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.7);
  font-weight: bold;
  margin: 5px 5px 5px 0px;
  border-radius: 5px;
  padding: 5px 8px;
  font-family: "Montserrat";

  &.orange {
    background-color: $orange;
    border: 1px solid #482f00;
  }

  &.blue {
    background-color: var(--brand-blue);
  }

  &.green {
    background-color: rgb(0, 112, 50);
    border: 1px solid rgb(0, 36, 16);
  }
}

.c-red {
  color: #ff666f;
}
.c-lred {
  color: #ff666f;
}
.c-darkred {
  color: #ff666f;
}
.c-pink {
  color: #ff19a5;
}
.c-lblue {
  color: var(--brand-blue) !important;
}
.c-verylightblue {
  color: #a3d7ff;
}
.c-official-blue {
  color: var(--brand-blue);
}
.c-green {
  color: $brand-green;
}
.c-orange {
  color: orange;
}
.c-gray {
  color: #787878;
}
.c-dark-gray {
  color: #707070;
}
.c-white {
  color: #eaeaea;
}
.c-black {
  color: #333;
}

.c-link-blue {
  color: var(--brand-blue);
}

.b,
.bold {
  font-weight: bold;
}

.t-10 {
  font-size: 10px;
}
.t-11 {
  font-size: 11px;
}
.t-12 {
  font-size: 12px;
}
.t-14 {
  font-size: 14px;
}
.t-16 {
  font-size: 16px;
}
.t-18 {
  font-size: 18px;
}
.t-20 {
  font-size: 20px;
}
.t-25 {
  font-size: 25px;
}
.t-30 {
  font-size: 30px;
}
.t-35 {
  font-size: 35px;
}
.t-40 {
  font-size: 40px;
}
.t-45 {
  font-size: 45px;
}
.t-50 {
  font-size: 50px;
}

.error-box {
  font-weight: bold;
  padding: 10px;
  border-radius: 20px;
  border: 2px solid red;
  background-color: rgba(0, 0, 0, 0.2);
  margin: 20px 0px;
  text-align: center;
}
