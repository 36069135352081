@import "scss/index";

html {
  height: 100%;
}

.connect-bg-container {
  background-image: url("./assets/images/quantum2-lifestyle.jpg");
}

.connect-bg-container.s17 {
  background-image: url("./assets/images/connect-bg-s17.webp");
}
