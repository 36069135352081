.shimmer {
  background: linear-gradient(90deg, #1e1e1e 25%, #2e2e2e 50%, #1e1e1e 75%);
  background-size: 200% 100%;
  animation: shimmer 5.5s infinite;
}

.theme_plainlight .shimmer {
  background: linear-gradient(90deg, #e0e0e0 25%, #d2d2d2 50%, #e0e0e0 75%);
  background-size: 200% 100%;
  animation: shimmer 5.5s infinite;
}

@keyframes shimmer {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}
