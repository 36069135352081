app-home {
  width: 100%;
  display: block;
  margin-top: 50px;

  .profile-photo {
    img {
      margin-right: 15px;
      margin-bottom: 15px;
      width: 100%;
      max-width: 215px;
    }
  }

  .basic_info {
    padding: 10px 0px 0px 0px;
    margin: 0px 0px 20px 0px;
    list-style: none;
    font-size: 15px;

    li {
      span {
        display: block;
        line-height: 18px;
      }
    }
  }

  #main_info {
    .bare_btn {
      display: block;
      margin-right: 15px;
    }
  }

  .active_notice {
    font-size: 18px;
    font-weight: bold;
  }
}
.news-item {
  align-items: center;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  background-color: #232323;
  transition: all 0.1s ease-in-out;
  animation: slide-down 0.4s ease;
  border-radius: 10px;

  .image-border {
    border-radius: 5px 5px 0px 0px;
  }

  .theme_plainlight & {
    background-color: #f5f5f5;
  }
  &:hover {
    transform: scale(1.01, 1.01);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  }

  img {
    width: 100%;
    display: block;
  }

  h6 {
    font-weight: bold;
  }

  small {
    line-height: 17px;
    display: inline-block;
  }
}

.rss-container {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  padding: 5px;
  // border: 1px solid #0000008c;
  // box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.6);

  .theme_plainlight & {
    background-color: #f5f5f5;
    // border: 1px solid #c6c6c6;
    // box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
  }
}

.new-addition {
  .new-addition-content {
    @media screen and (max-width: 500px) {
      flex-wrap: wrap;
    }
  }
  img {
    width: 200px;

    @media screen and (max-width: 500px) {
      width: 100%;
      max-width: 300px;
      align-self: center;
    }
  }
}

.offer-container {
  display: flex;

  @media screen and (max-width: 800px) {
    display: block;
  }
}
