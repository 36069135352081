.notification-item {
  display: flex;
  align-items: center;
  padding: 10px 0px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  text-decoration: none;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  background-color: #2e3133;
  margin: 5px 2px;
  border-radius: 5px;
  position: relative;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.4);
  .theme_plainlight & {
    background: #fff;
  }

  .color-bar {
    width: 3px;
    height: 100%;
    position: absolute;
    border-radius: 5px 0px 0px 5px;
  }

  &.unread {
    background-color: #383b3d;

    .theme_plainlight & {
      background: #f5f5f5;
    }
  }

  &:first-child {
    border-top: 1px solid transparent;
  }

  &:hover {
    background: #383b3d;
    text-decoration: none;
    border-radius: 5px;
    border-top: 1px solid transparent;

    .theme_plainlight & {
      background: #f5f5f5;
    }
  }

  .unread-badge {
    // align-self: auto;
    margin-top: 20px;
  }

  .notification-img {
    width: 40px;
    margin: 5px 5px 5px 10px;
    display: inline-block;
    // align-self: flex-start;
    transition: all 0.1s ease-in-out;
    transform: scale(1, 1);
    flex-shrink: 0;

    img {
      width: 100%;
      max-height: 100%;
    }
  }

  .subject {
    font-size: 12px;
    font-weight: bold;
    color: gray;
  }

  .description {
    font-size: 13px;
    max-height: 60px;
    overflow: hidden;
  }

  .original-post-text {
    font-size: 12px;
    color: gray;
    max-height: 40px;
    overflow: hidden;
  }

  .notification-data {
    flex: 1;
    width: 100%;
    overflow: hidden;
    margin-right: 5px;
  }

  .buttons {
    position: absolute;
    top: 5px;
    right: 5px;
    color: gray;
  }
}
