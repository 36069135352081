.outline-btn {
  font-weight: 700 !important;
  line-height: 24px !important;
  background-color: transparent !important;
  border: 3px solid var(--brand-blue);
  color: var(--brand-blue);
  border-radius: 3px !important;
  font-size: 18px !important;
  padding: 5px 12px 6px !important;
  cursor: pointer;
}

.facebook-login,
.linkedin-login {
  display: block !important;
}

.linkedin-login {
  background-color: #5bc0de !important;
  border-color: #46b8da !important;
}

.facebook-login {
  background-color: #337ab7 !important;
  border-color: #2e6da4 !important;
}

.btn {
  border-radius: 30px !important;
  font-weight: bold;
  font-family: "Helvetica Now Bold";
  // text-transform: uppercase;
  font-size: 14px;
  padding: 7px 20px;
  border: none;

  &.icon {
    display: flex;
  }

  &.btn-sm {
    font-size: 12px;
    padding: 4px 10px;
  }

  &:focus,
  &.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255);
  }
}
.btn-primary {
  background-color: var(--brand-blue) !important;
  color: white !important;
  // box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.4);
}

.btn-blank {
  color: #eaeaea;

  .theme_plainlight & {
    color: #121212 !important;
  }

  .mustang &,
  .register-custom-ui & {
    color: #eaeaea !important;
  }

  &:hover {
    color: var(--brand-blue) !important;
    svg {
      color: var(--brand-blue) !important;
    }
  }
}

.btn-success {
  border: 1px solid #00300c;
}

.btn-secondary {
  background-color: #333 !important;
  .theme_plainlight & {
    background-color: #121212 !important;
  }
}

.btn-light {
  background-color: #eaeaea !important;
}

.btn-danger {
  border: 1px solid #300000;
}

.prod-sphere-icon {
  width: 20px;
  height: 20px;
  margin-bottom: 2px;
}

button:disabled {
  cursor: not-allowed !important;
  opacity: 0.2;
}

button.fa-button {
  border: none;
  background: none;
  padding: 0px;
  color: white;

  .theme_plainlight & {
    color: #121212;
  }

  &.c-red fa-icon {
    color: $red !important;
  }
}

.circle-icon-btn {
  width: 30px;
  height: 30px;
  text-align: center;
  // background-color: rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  display: inline-block;
  line-height: 30px;
  color: #eaeaea;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  transform: scale(1, 1);
  flex-shrink: 0;
  // box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.8);

  &:active {
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 1);
  }

  .theme_plainlight & {
    color: #121212;
  }

  &:hover {
    transform: scale(1.1, 1.1);
    color: var(--brand-blue);

    &.is_favorite {
      color: #eaeaea;
    }
  }

  &.no-hover {
    &:hover {
      color: #eaeaea !important;

      .theme_plainlight & {
        color: #121212 !important;
      }
    }
  }

  &.red {
    color: #eaeaea;
    background-color: red;
  }

  &.orange {
    color: #eaeaea;
    background-color: #ff7d6b;
  }

  &.create-action {
    color: #eaeaea;
    background: var(--brand-blue);
  }

  &.purple {
    color: #eaeaea;
    background-color: purple;
  }

  &.green {
    color: #eaeaea;
    background-color: green;
  }

  &.blue {
    color: #eaeaea;
    background-color: var(--brand-blue);
  }

  &.red,
  &.orange,
  &.purple,
  &.green,
  &.blue,
  &.create-action {
    &:hover {
      color: #eaeaea !important;
    }
  }
}

fa-icon.creatae-action {
  color: #eaeaea;
  background: linear-gradient(to bottom, #01eeff, var(--brand-blue));
}

.no-border {
  border: none;
}

.tiny-link {
  font-size: 13px;
  cursor: pointer;
  outline: none;
  background-color: transparent;
  border: none;
  &:active {
    outline: none;
    border: none;
  }

  &:hover {
    color: var(--brand-blue);
  }

  &:focus {
    text-decoration: underline;
  }

  &.black {
    color: gray;

    .theme_plainlight & {
      color: black;
    }
  }

  &.dark {
    color: black;
  }

  &.red {
    color: red;
  }

  &.b {
    font-weight: bold;
  }
}

.menu-btn {
  display: inline-block;
  cursor: pointer;
  color: #eaeaea;
  font-weight: bold;
  font-size: 13px;
  text-align: center;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.7);
  font-weight: bold;
  margin: 5px 5px 5px 0px;
  border-radius: 5px;
  padding: 5px 8px;

  &.orange {
    background-color: $orange;
    border: 1px solid #482f00;
  }

  &.blue {
    background-color: var(--brand-blue);
  }

  &.green {
    background-color: rgb(0, 112, 50);
    border: 1px solid rgb(0, 36, 16);
  }
}

.s1plus-icon-md {
  width: 30px;
  height: 30px;
}

.header-btn {
  width: 30px;
  margin: 0px 5px;
  height: 30px;
  padding: 0px;
  position: relative;
}
