.ticketfield-list {
  position: absolute;
  top: 0px;
  background-color: #333;
  border: 2px solid rgba(255, 255, 255, 0.2);
  border-radius: 10px;
  animation: slide-down 0.4s ease;
  padding: 0px;

  .theme_plainlight & {
    background-color: #f5f5f5;
    border: 2px solid rgba(0, 0, 0, 0.2);
  }

  .ticketfield-option {
    padding: 5px 10px;
    cursor: pointer;
    transition: all 0.1s ease-in-out;
    list-style-type: none;

    &:hover {
      transform: scale(1.05, 1.05);
    }
  }
}
