.profile-info {
  .cover-photo {
    height: 250px;
    margin: 15px 0px;
    background-position: center;
    background-size: cover;
    border-radius: 20px;
    position: relative;

    .profile-back-container {
      position: absolute;
      bottom: 10px;
      left: 10px;
    }

    .profile-photo {
      width: 240px;
      height: 240px;
      border-radius: 120px;
      position: absolute;
      left: 50%;
      margin-left: -120px;
      bottom: -30px;
    }
  }

  .profile-details {
    display: flex;
    align-items: center;

    h1 {
      font-size: 2rem;
    }

    .left-col,
    .right-col {
      display: flex;
      flex: 1;
    }

    .left-col {
      justify-content: flex-end;
    }

    .center-col {
      flex: 2;
      text-align: center;
    }
  }
}

.attachment-list {
  padding: 0px;
  margin-bottom: 0px;
  li {
    list-style-type: none;
  }
}

.add-edit-skill {
  .community-skill-selector {
    display: flex;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);

    .theme_plainlight & {
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }

    .checkbox {
      padding: 10px;
    }

    .option {
      padding: 10px;
      width: 100%;

      .hourly-rate {
        background-color: rgba(255, 255, 255, 0.2) !important;
        color: #eaeaea;
        border-radius: 10px;
        width: 75px;
        padding: 0px 10px;
        border: none;

        .theme_plainlight & {
          color: #333;
          background-color: rgba(0, 0, 0, 0.1) !important;
        }
      }

      .skill-notes {
        background-color: rgba(255, 255, 255, 0.2) !important;
        color: #eaeaea;
        border-radius: 10px;
        height: 30px;
        font-size: 14px;
        padding: 5px;
        border: none;
        width: 100%;
        transition: all 0.1s ease-in-out;

        &:focus {
          height: 75px;
        }

        .theme_plainlight & {
          color: #333;
          background-color: rgba(0, 0, 0, 0.1) !important;
        }
      }
    }
  }
}

.skill-type-badge {
  font-size: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 2px 7px;
  border-radius: 10px;
  opacity: 0.7;
  font-weight: bold;
}

.browse-skill-pill {
  display: flex;
  justify-content: space-between;
  margin: 5px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  font-size: 20px;
  font-weight: bold;
  font-size: 20px;
  padding: 2px 5px;
  text-decoration: none;
  transition: all 0.1s ease-out;
  cursor: pointer;

  &:hover {
    color: var(--brand-blue);
  }

  .theme_plainlight & {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
}

.connect-autocomplete {
  position: absolute;
  top: 45px;
  width: 100%;
  max-width: 400px;
  border-radius: 20px;
  background-color: black;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
  padding: 10px;
  z-index: 9999;

  .theme_plainlight & {
    background-color: #f5f5f5;
  }
}

.member-results-overlay,
.post-detail-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  text-align: center;
  top: 0px;
  left: 0px;

  .close {
    position: absolute;
    top: 10px;
    right: 10px;
  }
}

.tiny-progress-bar {
  height: 2px;
  background-color: #0088cc;
}

.thread-pending-file-attachment {
  position: absolute;
  bottom: 50px;
  left: 10px;
  background-color: rgba(0, 0, 0, 0.8);
  padding: 10px;
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(255, 255, 255, 0.1);
  text-align: center;
}
.create-post-container,
.message-header {
  .add-channel-button {
    padding: 2px 5px;
    margin: 2px 3px;
    font-size: 12px;
    border: none;
  }

  .channel-list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .channel-button {
    border: none;
  }

  .create-post-selected-channel {
    background-color: rgba(255, 255, 255, 0.1);
    padding: 2px 5px;
    border-radius: 10px;
    margin: 2px 3px;
    font-size: 12px;
    white-space: nowrap;

    fa-icon {
      cursor: pointer;
    }

    .theme_plainlight & {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }
}
.create-post-container {
  background-color: #232323;
  width: 100%;
  border-radius: 10px;
  padding: 10px;
  max-width: 1000px;
  position: relative;
  margin: auto;

  &.hovering {
    background-color: rgba(0, 128, 0, 0.548);
  }

  .profile-photo {
    width: 60px;
    height: 60px;
    @extend .round;
    @extend .image-border;

    &.tiny {
      width: 25px;
      height: 25px;
    }
  }

  .theme_plainlight & {
    background-color: #f5f5f5;
  }

  .post-progress-bar {
    height: 2px;
    background-color: #0088cc;
  }

  .attachment {
    padding: 5px;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 5px;
    margin: 5px;
    position: relative;
    display: flex;
    align-items: center;

    .remove {
      position: absolute;
      top: -10px;
      right: -10px;
      cursor: pointer;
    }
    .theme_plainlight & {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }
}

.edit-post {
  .post-progress-bar {
    height: 2px;
    background-color: #0088cc;
  }

  .attachment {
    padding: 5px;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 5px;
    margin: 5px 10px 5px 0px;
    position: relative;
    display: flex;
    align-items: center;

    .remove {
      position: absolute;
      top: -10px;
      right: -10px;
      cursor: pointer;
    }
    .theme_plainlight & {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }
}

.showcase-selected-skill {
  background-color: rgba(255, 255, 255, 0.1);
  padding: 2px 5px;
  border-radius: 10px;
  margin: 2px 3px;
  font-size: 12px;

  fa-icon {
    cursor: pointer;
  }

  .theme_plainlight & {
    background-color: rgba(0, 0, 0, 0.1);
  }
}

.channel-selector {
  width: 200px;
  max-height: 382px;
  overflow: auto;
  position: absolute;
  left: 0px;
  @extend .blur-bg;
  z-index: 9999;
  border-radius: 20px;
  padding: 10px;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.5);
}

.community-employee-badge {
  width: 20px;
  height: 20px;

  &.sm {
    height: 15px;
    width: 15px;
  }
}

.community-post {
  background-color: #232323;
  padding: 10px;
  text-align: left;
  border-radius: 10px;
  margin: 10px auto;
  margin-bottom: 50px;
  // box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
  // border: 1px solid rgba(255, 255, 255, 0.2);
  max-width: 1000px;
  position: relative;

  &.beta-header {
    text-align: center;
    background-color: var(--brand-blue) !important;
    color: white;
    font-weight: bold;
  }

  &.employee-only {
    border: 1px solid var(--brand-blue);
  }

  &.queued {
    opacity: 0.3;
  }

  .main-post-text {
    font-size: 16px;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .main-post-text,
  .reply-container {
    p {
      margin-bottom: 0px;
    }

    ol li[data-list="bullet"] {
      list-style: disc;
    }

    blockquote {
      border-left: 3px solid;
      padding-left: 10px;
    }

    .ql-code-block-container {
      background-color: #171717;
      color: #f8f8f2;
      overflow: visible;
      font-family: monospace;
      font-size: 14px;
      padding: 5px;
      border-radius: 5px;

      .theme_plainlight & {
        background-color: #dcdcdc;
        color: #333;
      }

      .ql-code-block {
        white-space: pre;
      }
    }
  }

  .main-post-subject {
    font-size: 24px;
    padding: 0px 5px;
    @extend .brandfont-black;
  }

  .theme_plainlight & {
    background-color: #f5f5f5;
    // border: 1px solid rgba(0, 0, 0, 0.2);

    &.employee-only {
      border: 1px solid var(--brand-blue);
    }
  }

  .message-header {
    position: relative;
    display: flex;
    margin-bottom: 10px;
    align-items: center;
    flex-wrap: wrap;

    .author-name {
      margin-left: 40px;
      margin-right: 10px;
      margin-bottom: 0px;
      color: white;

      .theme_plainlight & {
        color: #000;
      }
    }

    .time-created {
      opacity: 0.7;
      font-size: 13px;
    }

    .channels {
      flex: 1;
      justify-content: flex-end;
      display: flex;
      flex-wrap: wrap;

      .channel,
      .topic {
        font-size: 12px;
        white-space: nowrap;
        font-weight: bold;
        display: inline-block;
        margin: 3px;
        padding: 2px 5px;
        border-radius: 5px !important;
        cursor: pointer;
        background-color: rgba(255, 255, 255, 0.1);

        &.green {
          background-color: rgba(0, 182, 48, 0.481);
        }

        &.blue {
          background-color: var(--brand-blue);
        }

        &.disabled {
          opacity: 0.5;
        }

        .theme_plainlight & {
          background-color: rgba(0, 0, 0, 0.1);
          &.blue {
            background-color: var(--brand-blue);
            color: #eaeaea;
          }
        }
      }

      .topic {
        background-color: var(--brand-blue);
        font-weight: normal;

        .theme_plainlight & {
          background-color: var(--brand-blue);
          color: #eaeaea;
        }
      }
    }

    .profile-photo {
      position: absolute;
      top: -20px;
      left: -20px;
      width: 50px;
      height: 50px;
      cursor: pointer;
      @extend .image-border;
      @extend .round;
    }
  }

  .generic-file-attachment {
    padding: 5px 10px;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 5px;
    margin: 5px;
    position: relative;
    display: inline-block;

    fa-icon {
      cursor: pointer;
    }
  }

  .message-container {
    margin: 10px 20px;
  }

  .post-attachments {
    display: flex;
    flex-wrap: wrap;
    margin-left: 0px;
    padding-left: 0px;

    .attachment {
      width: 100%;

      video {
        max-height: 500px;
      }

      img {
        width: auto;
        max-width: 100%;
        max-height: 300px;
        cursor: pointer;
      }
    }

    img {
      width: 100%;
    }

    &.multiple {
      .attachment.is-image {
        width: auto;

        img {
          width: auto;
          height: 150px;
          margin: 5px;
        }
      }
    }
  }

  .reply-container {
    .attachment {
      img {
        max-width: 300px;
      }
    }
  }

  .replying-attachment {
    padding: 5px;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 5px;
    margin: 5px;
    position: relative;
    width: auto;

    .remove {
      position: absolute;
      top: -10px;
      right: -10px;
      cursor: pointer;
    }
    .theme_plainlight & {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }

  .post-progress-bar {
    height: 2px;
    background-color: #0088cc;
  }

  .timestamp {
    padding: 4px 5px;
  }

  .post-actions {
    margin-right: 10px;
    fa-icon {
      cursor: pointer;
      margin-left: 10px;
      font-size: 14px;
    }
  }
}

.general-profile-photo {
  width: 50px;
  height: 50px;
  cursor: pointer;
  @extend .image-border;
  @extend .round;
}

.mention-option {
  padding: 5px;
  border-radius: 10px;
  border: none;
  cursor: pointer;

  &.selected {
    background-color: var(--brand-blue);
  }
}

.profile-photo {
  &.tiny {
    width: 25px;
    height: 25px;
    border-radius: 13px;
  }
}

.post-audio-play {
  padding: 10px;
  @extend .image-border;
  cursor: pointer;
  @extend .hover-zoom-sm;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  overflow: hidden;

  .theme_plainlight & {
    background-color: #f5f5f5;
  }

  fa-icon {
    font-size: 60px;
  }
}

.community-onboarding-box {
  background-color: rgba(0, 0, 0, 0.9);
  padding: 20px;
  border-radius: 20px;

  .theme_plainlight & {
    background-color: #f5f5f5;
  }

  li {
    list-style-type: none;
  }
}

.search-result-actions {
  padding-top: 10px;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid gray;

  .action {
    font-weight: bold;
    color: gray;
    cursor: pointer;

    &.green {
      color: #1dad00;
    }

    &.red {
      color: red;
    }
  }

  .view-post {
    color: var(--brand-blue);
    font-weight: bold;
    cursor: pointer;
  }
}

.message-embed {
  position: relative;
  margin-top: 12px;

  .close-btn {
    position: absolute;
    z-index: 1;
    top: -10px;
    right: -8px;
    cursor: pointer;
  }

  iframe {
    width: 100%;
    height: 100%;
  }

  &--youtube {
    width: 100%;
    padding-top: 56.25%;

    iframe {
      position: absolute;
      top: 0;
    }
  }

  &--soundcloud {
    align-self: flex-start;
    width: 100%;
    max-width: 500px;

    .close-btn {
      top: -12px;
      right: -10px;
    }
  }

  &--spotify {
    width: 100%;
    max-width: 500px;
    height: 352px;
  }

  &--track,
  &--episode,
  &--show {
    height: 152px;
  }
}

.beta-status-container {
  @media screen and (max-width: 800px) {
    .beta-status {
      width: 100%;
    }
  }
}

.comments-li {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
