.audio-player {
  position: fixed;
  display: flex;
  justify-content: center;
  bottom: 0px;
  width: 100%;
  left: 0px;
  z-index: 9999;
  background-color: $dark;
  border-top: 1px solid var(--brand-blue);

  .theme_plainlight & {
    background-color: $grey;
    border-color: var(--brand-blue);
  }

  @media (min-width: $break-small) {
    height: 60px;
  }

  audio {
    display: none;
  }

  button {
    background: none;
    border: none;
    color: $white;
    outline: none;

    &:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.85);
      border-radius: 30px !important;
    }

    .theme_plainlight & {
      color: var(--brand-blue);
    }
  }

  &__mask {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100% - 60px);
  }

  &__error {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $dark;

    .theme_plainlight & {
      background-color: $grey;
    }

    p {
      margin: 0;

      .theme_plainlight & {
        color: var(--brand-blue);
      }
    }
  }

  &__contents {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (min-width: $break-small) {
      flex-direction: row;
      align-items: center;
      padding: 0 32px;
    }
  }

  &__mobile-play {
    @media (min-width: $break-small) {
      display: none;
    }
  }

  &__close {
    &--mobile {
      @media (min-width: $break-small) {
        display: none;
      }
    }

    &--desktop {
      display: none;

      @media (min-width: $break-small) {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
      }
    }
  }

  &__controls {
    display: none;

    @media (min-width: $break-small) {
      display: flex;
      flex: 0 0 84px;
      align-items: center;
      justify-content: space-between;
    }
  }

  &__download,
  &__post-link {
    display: none;

    @media (min-width: $break-small) {
      display: block;
    }
  }

  &__timeline {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $white;

    .theme_plainlight & {
      color: var(--brand-blue);
    }

    @media (min-width: $break-small) {
      margin: 0 32px;
      flex: 100%;
      max-width: 480px;
    }
  }

  &__timestring {
    display: none;

    @media (min-width: $break-small) {
      display: block;
      font-size: 14px;
    }
  }

  &__progress {
    height: 8px;
    width: 100%;
    accent-color: var(--brand-blue);
    transition: transform 300ms ease;
    appearance: none;

    &::-webkit-progress-bar {
      background-color: #e5e5e5;
    }

    &::-webkit-progress-value {
      background: var(--brand-blue);
    }

    @media (min-width: $break-small) {
      height: 4px;
      margin: 0 16px;

      &:hover {
        transform: scaleY(2);
        cursor: pointer;
      }
    }
  }

  &__file {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 4px 8px;

    @media (min-width: $break-small) {
      flex: 0 0 350px;
      margin: 0;
    }
  }

  &__filename {
    margin: 0;
    font-size: 14px;
    color: $white;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    .theme_plainlight & {
      color: var(--brand-blue);
    }

    @media (min-width: $break-small) {
      max-width: initial;
    }
  }

  &__file-controls {
    display: flex;
    position: relative;
    flex: 0 0 72px;
    justify-content: flex-end;

    @media (min-width: $break-small) {
      flex: initial;
      justify-content: initial;
    }
  }

  &__list {
    position: fixed;
    right: 0px;
    bottom: 50px;
    width: 100%;
    height: 320px;
    border-radius: 10px;
    background-color: $dark;
    overflow: hidden;
    pointer-events: none;
    transform: translateY(600px);
    border: 1px solid lighten($dark, 10%);

    .theme_plainlight & {
      background-color: $grey;
      border-color: rgba(var(--brand-blue), 0.2);
    }

    @media (min-width: $break-small) {
      position: absolute;
      width: 480px;
      height: 480px;
    }

    &.visible {
      transition: all 150ms ease;
      transform: translateY(0);
      pointer-events: initial;
    }
  }

  &__list-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid lighten($dark, 10%);
    padding: 8px 16px;

    .theme_plainlight & {
      border-bottom-color: var(--brand-blue);

      .switch__label {
        color: var(--brand-blue) !important;
      }
    }

    > p {
      margin: 0;
      color: $white;
      font-size: 22px;
      font-weight: bold;

      .theme_plainlight & {
        color: var(--brand-blue);
      }
    }
  }

  &__list-contents {
    height: 430px;
    overflow: scroll;
    padding-top: 8px;
  }

  &__list-item {
    display: flex;
    align-items: center;
    font-size: 16px;
    color: $white;
    margin: 0px 16px;
    cursor: pointer;
    height: 30px;

    .theme_plainlight & {
      color: var(--brand-blue);
    }

    p {
      margin: 0;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &.active {
      color: var(--brand-blue);
    }
  }

  &__spinner {
    position: relative;
    width: 30px;
    height: 30px;
  }
}
