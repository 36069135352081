.brand-btn {
  cursor: pointer;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 2.5rem;
  display: -ms-inline-flexbox;
  display: inline-flex;
  height: 3.85625rem;
  -ms-flex-pack: center;
  justify-content: center;
  max-width: 100%;
  text-transform: none;
  width: 100%;
  @extend .brandfont-bold;
  font-weight: 700;
  font-style: normal;
  background-color: var(--brand-blue);
  background-image: linear-gradient(110.22deg, var(--brand-blue) 2.44%, #b538f6 74.4%);
  background-repeat: no-repeat;
  background-size: 0 100%;
  border: none;
  color: #eaeaea;
  transition: all 0.2s linear;

  &.disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }

  &:hover {
    &:not(.disabled) {
      background-color: #b538f6;
      background-size: 100% 100%;
      box-shadow: none;
      color: #eaeaea;
      outline: none;
      transition: all 0.5s cubic-bezier(0, 0, 0.23, 1);
    }
  }
}

.brand-btn-reverse {
  border-radius: 1000px;
  color: #eaeaea;
  border: 2px solid #eaeaea;
  cursor: pointer;
  display: inline-block;
  @extend .brandfont;
  font-weight: 700;
  font-style: normal;
  font-size: 0.9rem;
  font-weight: 700;
  letter-spacing: normal;
  line-height: 1.375;
  max-width: 100%;
  padding: 8px 20px;
  text-align: center;
  text-transform: capitalize;
  transition: all 0.2s ease-in-out;
  vertical-align: top;
  background: transparent;

  &.sm {
    font-size: 12px;
    padding: 3px 15px;
    border: 1px solid #eaeaea;
  }

  &:hover {
    border: 2px solid var(--brand-blue);
    background: var(--brand-blue);
    color: inherit;
    text-decoration: none;

    &.sm {
      border: 1px solid var(--brand-blue);
    }
  }

  .theme_plainlight & {
    color: #333;
    border: 2px solid #333;

    &:hover {
      border: 2px solid var(--brand-blue);
      background: var(--brand-blue);
      color: white;
    }
  }
}

.brand-btn-normal {
  border-radius: 1000px;
  color: #eaeaea;
  border: 2px solid var(--brand-blue);
  background-color: var(--brand-blue) !important;
  cursor: pointer;
  display: inline-block;
  @extend .brandfont;
  font-weight: 700;
  font-style: normal;
  font-size: 0.9rem;
  font-weight: 700;
  letter-spacing: normal;
  line-height: 1.375;
  max-width: 100%;
  padding: 8px 14px;
  text-align: center;
  text-transform: capitalize;
  transition: all 0.2s ease-in-out;
  vertical-align: top;

  &.sm {
    font-size: 12px;
    padding: 3px 15px;
  }

  &:hover {
    border: 2px solid var(--brand-blue);
    background: var(--brand-blue);
    color: inherit;
    text-decoration: none;

    &.sm {
      border: 1px solid var(--brand-blue);
    }
  }

  .theme_plainlight & {
    color: #333;
    border: 2px solid #333;

    &:hover {
      border: 2px solid var(--brand-blue);
      background: var(--brand-blue);
      color: white;
    }
  }
}

.autocomplete-dropdown {
  position: relative;

  button {
    text-align: left;
  }

  .form-control {
    transition: all 0.3s ease-in-out;
    border: 2px solid #232323;
    cursor: pointer;

    .theme_plainlight & {
      border: 2px solid #c2c2c2;
    }

    &:hover {
      border: 2px solid var(--brand-blue);
    }

    fa-icon {
      position: absolute;
      top: 7px;
      right: 15px;
      color: var(--brand-blue);
    }
    .editable-input {
      &:active,
      &:focus {
        outline: none;
      }
    }

    .selected {
      @extend .brandfont-black;
      color: var(--brand-blue);
    }
    .hint {
      opacity: 0.5;
    }
  }

  .autocomplete-mask {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    background-color: blue;
  }

  .autocomplete-container {
    position: absolute;
    z-index: 999999;
    background-color: #232323;
    padding: 10px;
    border-radius: 20px;
    max-height: 500px;
    overflow: auto;
    width: 100%;
    margin-top: 10px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.5);

    .theme_plainlight & {
      background-color: #f5f5f5;
    }

    .select-item {
      padding: 5px 10px;
      font-weight: bold;
      cursor: pointer;
      border-radius: 20px;

      .metadata {
        font-size: 12px;
        background-color: rgba(255, 255, 255, 0.2);
        font-weight: bold;
        padding: 2px 5px;
        border-radius: 5px;
      }

      &:hover,
      &.preselected {
        background-color: var(--brand-blue);
        color: white;
      }
    }
  }
}

span.small-pill {
  font-size: 14px;
  border: 2px solid var(--brand-blue);
  border-radius: 20px;
  padding: 3px 8px;
  margin: 5px 10px 5px 0px;
  font-weight: bold;

  fa-icon {
    cursor: pointer;

    &:hover {
      color: var(--brand-blue);
    }
  }
}

.blur-bg {
  background-color: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(10px);

  .theme_plainlight & {
    background-color: rgba(255, 255, 255, 0.7);
  }
}

.item-tag {
  background-color: rgba(255, 255, 255, 0.1);
  padding: 2px 5px;
  border-radius: 10px;
  margin: 2px 3px;
  white-space: nowrap;
  position: relative;

  &.more-pad {
    padding: 8px 10px;
  }

  fa-icon {
    cursor: pointer;

    &.top-right {
      position: absolute;
      top: -5px;
      right: -5px;
    }
  }

  .theme_plainlight & {
    background-color: rgba(0, 0, 0, 0.1);
  }
}

.content-bg {
  background-color: #131313;
  border-radius: 10px;
  padding: 10px;

  .theme_plainlight & {
    background-color: #dcdcdc;
  }
}
