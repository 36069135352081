@mixin set-theme-plain-light {
  background-color: white;
  color: #121212;
}

@mixin set-theme-plain-dark {
  background-color: #121212;
  color: #eaeaea;
}

.theme_plainlight {
  background-color: white;
  color: #121212;
}

.theme_plaindark {
  background-color: #121212;
  color: #eaeaea;
}

.theme_sphere,
.theme_sphere1 {
  background: rgb(23, 36, 43);
  background: linear-gradient(0deg, #2b4050, #1f2f3b);
  color: #eaeaea;
}

.theme_atom1 {
  background-image: url("/assets/images/theme_atom1.png");
  color: #eaeaea;
}

.theme_atom2 {
  background-image: url("/assets/images/theme_atom2.png");
  color: #eaeaea;
}

.theme_s14 {
  background-image: url("/assets/images/theme_s14.png");
  color: #eaeaea;
}

.theme_s142 {
  background-image: url("/assets/images/theme_s142.png");
  color: #eaeaea;
}

.theme_blue {
  background-image: url("/assets/images/theme_blue.png");
  color: #eaeaea;
}

.theme_green {
  background-image: url("/assets/images/theme_green.png");
  color: #eaeaea;
}

.theme_red {
  background-image: url("/assets/images/theme_red.png");
  color: #eaeaea;
}

.theme_dark {
  background-image: url("/assets/images/theme_dark.png");
  color: #eaeaea;
}

.theme_plainlight {
  section {
    .section-header-link,
    button {
      @include set-theme-plain-light();
      background-color: transparent;
    }
  }
}

.theme_plaindark {
  section {
    .section-header-link,
    button {
      @include set-theme-plain-dark();
      background-color: transparent;
    }
  }
  button fa-icon {
    @include set-theme-plain-dark();
    background-color: transparent;
  }
}
